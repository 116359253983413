import { useState,useRef, useEffect } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
const axios = require('axios');

import {
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  FormGroup ,
  Divider,
  Grid
} from '@material-ui/core';
import { createTestimonial } from '../apiCalls/apiCalls';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateTrainer = () => {
  const navigate = useNavigate();
  const classes = style();

  const [trainer, setTrainer] = useState(
    {
      name:"",
      subject:"",
      facebookLink:"",
      linkedInLink:"",
      twitterLink:"",
      youtubeLink:"",
      instagramLink:"",
      image:"image",
      trainers: [],
      description:"",
    }
  );
  const { name,subject,facebookLink,linkedInLink,twitterLink ,youtubeLink,description,instagramLink,trainers,image} = trainer;

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setTrainer({
      ...trainer,
      [name]: e.target.value,
    });
  };

  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setTrainer({
      ...trainer,
      [name]:  e.target.files[0]
    });
  };

  const trainerSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      subject:subject,
      linkedInLink:linkedInLink,
      facebookLink:facebookLink,
      twitterLink:twitterLink,
      youtubeLink:youtubeLink,
      description:description,
      instagramLink:instagramLink
    };
    const formData = new FormData();

    if (image) {
      formData.append("file", image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));


    axios({
      method: "post",
      // url: "http://localhost:9000/api/admin/trainers/create",
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/trainers/create",
      url: "https://api.luminartechnolab.com/api/admin/trainers/create",

      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function(response){

      setTrainer({...trainer,
        name:"",
        subject:"",
        facebookLink:"",
        linkedInLink:"",
        twitterLink:"",
        instagramLink:"",
        image:null,
        trainers: [],
        description
      })
      navigate('/app/trainers')
    }).catch(function(response){
      console.log("err",response)
    })


    // createTestimonial(data).then((datas) => {
    //   console.log("name",data)
    //   if (datas.name === "Error") {
    //     alert(datas.message);
    //   } else {
    //     let newArray= [...testimonials,datas.data]
    //     setTestimonial({
    //       ...testimonial, testimonials:newArray
    //       })
    //       navigate('/app/testimonials')
    //   }
    // });
  };
  return(
    
    <form  onSubmit={trainerSubmitHandler}>
      <h2>Create Trainer</h2>
    <div className={classes.container}>
      <Grid item xs={12}>
          <Grid item xs={12}>
          <FormGroup className={classes.formGroup}  >
            <Grid> 
              <Card className={classes.card}>
                <CardContent>
                 <label>Name</label>
                  <TextField        
                     sx={{ maxWidth: 1000 }}
                     fullWidth
                     name={'name'}
                     value={name}
                     onChange={handleChange('name')}
                     placeholder="Full Name"
                     variant="outlined"
                     required
                  />
                 <br/><br/>
                 <label>Subject</label>
                 <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'subject'}
                    value={subject}
                    onChange={handleChange('subject')}
                    placeholder="Course"
                    variant="outlined"
                    required
                  /><br/><br/>
                  <label>LinkedIn Link</label>
                    <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'linkedInLink'}
                    value={linkedInLink}
                    onChange={handleChange('linkedInLink')}
                    placeholder="LinkedIn Link"
                    variant="outlined"
                    required
                  /><br/><br/>
                  <label>Facebook link</label>
                  <TextField
                  sx={{ maxWidth:  1000  }}
                   fullWidth
                  name={'facebookLink'}
                  value={facebookLink}
                  onChange={handleChange('facebookLink')}
                  placeholder="Facebook Link"
                  variant="outlined"
                  required
                /><br/><br/>

                  <label>Youtube Link</label>

                 <TextField
                  sx={{ maxWidth:  1000  }}
                  fullWidth
                  name={'youtubeLink'}
                  value={youtubeLink}
                  onChange={handleChange('youtubeLink')}
                  placeholder="Youtube Link"
                  variant="outlined"
                  required
               /><br/><br/> 
               <label>Instagram Link</label>
                                <TextField
                  sx={{ maxWidth:  1000  }}
                  fullWidth
                  name={'instagramLink'}
                  value={instagramLink}
                  onChange={handleChange('instagramLink')}
                  placeholder="instagramLink"
                  variant="outlined"
                  required
               /><br/><br />
                    <label>Description  (Maximum 500 Characters)</label>

             <TextField
               sx={{ maxWidth:  1000  }}
               fullWidth
               name={'description'}
               value={description}
               onChange={handleChange('description')}
               placeholder="description"
               variant="outlined"
               multiline
               inputProps={{ maxLength: 500 }}
               maxRows={3}
               rows={3}
              /><br/><br/><br></br>

                    <label>Image</label>

                    <input type='file' 
                  onChange={handleChangeImg('image')}
                  name={'image'}
                  key={'image'}
                  >
                  </input>
                  <br /> <br /> <br />
            <Button color="primary" variant="contained" type="submit" >
              Create
            </Button>
                </CardContent>
       </Card> 
     </Grid>
    <br /> <br /> <br />
          </FormGroup>
          </Grid>
      </Grid>
    </div>
    </form>
  )
}

export default CreateTrainer;
