import React from 'react'
import './Welcomepage.css'

function Welcomepage() {
    return (
        <div className='Content'>
            <h1>Welcome</h1>
            <p>Luminartechnolab</p>
            <h2>"Advancement Through Technology"</h2>
            
        </div>
    )
}

export default Welcomepage;
