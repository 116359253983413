import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Link } from '@material-ui/core';
import { useNavigate } from 'react-router-dom'
import Welcomepage from 'src/components/Welcomepage';
const Dashboard = (props) => {
  const navigate = useNavigate();
  if (props.authorized === false) {
    return navigate('/');
  }
  const token = localStorage.getItem('token');
  if (!token) {
    window.location.href = '/login';
  }
  return (
    <>
      <Helmet>
        <title>Dashboard |Luminar technolab</title>
      </Helmet>


      <Welcomepage />

    </>
  )
};

export default Dashboard;
