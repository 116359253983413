import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import {useNavigate,Link} from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  Button,
  Grid,
  CardContent,
  TextField,
  InputAdornment ,
  SvgIcon 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getAllPayments,deletePayment } from '../apiCalls/apiCalls';
import PlacementSearch from './PaymentSearch';
import { Search as SearchIcon } from 'react-feather';
const style = makeStyles({
  titleItemRight: {
    color: "white",
    backgroundColor: "blue",
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
  titleBar :{
   padding : "30px"
  },
  Card: {
    width: 50,
    margin: 'auto'
  },
  Media: {
    height: '100%',
    width: '100%'
  }
});
const PaymentList = () => {
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
  const [payment, setPayment] = useState([{ payments: [], }]);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);

  const { payments } = payment;
  const navigate = useNavigate();
  const classes = style();

  useEffect(() => {
    getAllPayments().then((data) => {
      setPayment({
        ...payment, payments:data.data
      })
      setLimit(data.TotalNoPages)

    })       
  }, []);

  const allListHandler = ()  => {
    getAllPayments()
      .then((data) => {
        if(data){
          setPayment({
            ...payment, payments:data.data
          })  
          setLimit(data.TotalNoPages)
          
        }
         else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllPayments(pageNumber)
      .then((data) => {
        if (data) {
            setPayment({
              ...payment, payments:data.data
            })  
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const removePageHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deletePayment(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };


  return (
    <Card >

     <PerfectScrollbar>
     <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
     <Box >

    </Box>
        <Box sx={{ minWidth: 'auto' }}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>OrderId</TableCell>
                <TableCell>PaymentId</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>CourseName</TableCell>
                <TableCell>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
            {payments && payments.map((customer, i) => {
              return(
                <TableRow
                  hover
                   key={i}
                >
                  <TableCell>{i+1} </TableCell>
                  <TableCell>{customer.firstName} </TableCell>
                  <TableCell>{customer.email} </TableCell>
                  <TableCell>{customer.phone} </TableCell>
                  <TableCell>
                  {moment(customer.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                  <TableCell>{customer.fee} </TableCell>
                  <TableCell>{customer.orderId} </TableCell>
                  <TableCell>{customer.transactionId} </TableCell>
                  <TableCell>{customer.status} </TableCell>

                  <TableCell>{customer.title} </TableCell>
                  <TableCell>
                  <Link to="#">

                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removePageHandler(customer._id)
                                }
                              }) 
                            }}
                          />
                          </Link>
                          </TableCell>
                </TableRow>
              )
              })
            }
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination
          color="primary"
          count={limit}
          size="small"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Card>
  )
};



export default PaymentList;
