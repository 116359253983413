import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import { getAllEnquiry, deleteEnquiry } from '../apiCalls/apiCalls';

const Enquiry = () => {

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
 
  const [limit, setLimit] = useState(0);
  const [enquiry, setEnquiry] = useState([{ enquiries: [], }]);
  const { enquiries } = enquiry;
  const navigate = useNavigate();

  useEffect(() => {
    getAllEnquiry().then((data) => {
      setEnquiry({
        ...enquiry,
        enquiries: data.data
      });
      setLimit(data.TotalNoPages)
    });
  }, []);
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllEnquiry(pageNumber)
    .then((data) => {
      if (data) {
        setEnquiry({
          ...enquiry,
          enquiries: data.data
        });
        setLimit(data.TotalNoPages)

      } else {
        alert(data.message);
      }
    })
    .catch((err) => {
      alert(err.message);
    });
  };
  const allListHandler = () => {
    getAllEnquiry()
      .then((data) => {
        if (data) {
          setEnquiry({
            ...enquiry,
            enquiries: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removeEnquiryHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deleteEnquiry(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };

  return (
    <Card       sx={{
      minHeight: '100%',
      py: 3
    }}>
      <PerfectScrollbar>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
        <Box >
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  placeholder="name"
                  variant="outlined"
                  required
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CreatedAt</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>PhoneNumber</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {enquiries && enquiries.filter((val)=>{
                  if(search==""){
                    return val
                  }else if(search){
                    if(val.fullName){
                      if(val.fullName.toLowerCase().includes(search.toLocaleLowerCase())){
                        return val
                      }
                    }
                  }
                }).map((customer,i) =>{
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {moment(customer.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>{customer.fullName}</TableCell>
                        <TableCell>{customer.phoneNumber}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>{customer.city}</TableCell>
                        <TableCell>{customer.course}</TableCell>
                        <TableCell>{customer.message}</TableCell>
                        <TableCell>
                        <Link to="#">

                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removeEnquiryHandler(customer._id)
                                }
                              }) 
                            }}
                          />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>


      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination color="primary" count={limit} size="small" page={page} onChange={handleChange} />
      </Box>
    </Card>
  );
};

export default Enquiry;
