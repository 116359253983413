import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const style = makeStyles((theme) => ({
   root: {
     padding: theme.spacing(2),
     position:'absolute',
     top:theme.spacing(5)
   },
   dialogContent: {
       textAlign:'center'
   },
   DialogActions:{
    textAlign:'center'
   }
 }));
export default function ConfirmDialogue(props) {
    const classes = style();
  const { confirmDialog, setConfirmDialog } = props;
  return (
    <Dialog open={confirmDialog.isOpen} classes={{paper:classes.root}}>
      <DialogTitle></DialogTitle>
      <DialogContent classes={{paper:classes.dialogContent}}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions classes={{paper:classes.DialogActions}}>
        <Button text="No"  color="secondary" variant="contained" onClick={()=>{
            setConfirmDialog({...confirmDialog,isOpen:false})
        }}>
          No
        </Button>
        <Button text="Yes" color="primary" variant="contained" onClick={confirmDialog.onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
