
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import {useNavigate,Link} from 'react-router-dom';
import { getAllPlacements,deletePlacement } from '../apiCalls/apiCalls';
import { makeStyles } from '@material-ui/styles';
import { getPDF } from '../apiCalls/apiCalls';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const style = makeStyles(theme => ({
  button: {
    // marginBottom: 20,
    // marginRight: 10

  },
    formControl: {
     minWidth: 85,
     borderRadius: "10%",
    // backgroundColor: '#5664d2',
    // minWidth: 70,  
    // height:36,
    // //minHeight : 4,
    // marginBottom: 20,
    // marginRight: 10,
    // color:'white',
     display: 'flex',

  },
  buttonFont:{
    color:'#5664d2'
  },

}));
const PlacementSearch = () => {

  const [option, setOption] = React.useState('');

  const classes = style();
  const handleChange = (event) => {
  setOption(event.target.value);
  };
  const savePDF = () => {
   return getPDF() // API call
     .then((response) => {
       const blob = new Blob([response.data], {type: 'application/pdf'})
       const link = document.createElement('a')
       link.href = window.URL.createObjectURL(blob)
       link.download = `placement.pdf`
       link.click()
     })
 }

  return(
  <Box  sx={{
    display: 'flex',
    justifyContent: 'flex-end'
  }}>

{/* 
<Button className={classes.button}
    color="primary"
    variant="contained"
  >
    Export 
  </Button> */}
<Link to={`/app/Addplacement`}>
  <Button 
      color="primary"
      variant="contained"
  >
    Add 
  </Button>
  <br/>
  <br/>
  </Link>
  {/* <Box>
<FormControl className={classes.formControl} >
        <InputLabel id="demo-simple-select-label" className={classes.buttonFont}>Export</InputLabel>
        <Select 
          labelId="demo-simple-select-label"
          id="demo-simple-select"
         value={option}
          label="option"
          onChange={handleChange}
        >
          <MenuItem value={10}>Copy</MenuItem>
          <MenuItem value={20}>CSV</MenuItem>
          <MenuItem value={30}>Excel</MenuItem>
          <MenuItem value={40} onClick={savePDF}>PDF</MenuItem>
          <MenuItem value={50}>Print</MenuItem>
        </Select>
</FormControl>
</Box> */}
</Box>
);
}

export default PlacementSearch;
