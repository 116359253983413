import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import TrainerList from '../components/trainers/TrainerCard';
import TrainerHeader from '../components/trainers/TrainerHeader';
const Trainers = () => (
  <>
    <Helmet>
      <title>Luminar Technolab</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <TrainerHeader />
        <br/>
        <Box sx={{ minWidth: 1050 }}>
          <TrainerList  />
        </Box>
      </Container>
    </Box>
  </>
);

export default Trainers;
