import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Card,
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getPage, updatePage } from '../apiCalls/apiCalls';

const PageEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getPage(id).then((data) => {
      setTitle({ ...title, title: data.title });
      setStatus({ ...status, status: data.status });
    });
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setTitle({ ...title, [name]: e.target.value });
    setStatus({ ...status, [name]: e.target.value });
  };


  const pageUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
      title:title.title,
      status : status.status
    };
    updatePage(data,id).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      } else {
          navigate('/pages')
      }
    });
  };
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            title: '',
            status: ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('title is required'),
            status: Yup.string().max(255).required('status is required')
          })}
          onSubmit={() => {
            navigate('/app/pages', { replace: true });
          }}
        >
          {({ errors, handleBlur, handleSubmit, isSubmitting, touched }) => (
            <form onSubmit={pageUpdateHandler}>
          
              <TextField
                fullWidth
                margin="normal"
                name={title}
                onBlur={handleBlur}
                onChange={handleChange('title')}
                value={title.title}
                variant="outlined"
                required
              />
              <InputLabel id="label">{status.status == true ? 'Active' : 'Inactive'}</InputLabel>
              <Select onChange={(e) =>{
                setStatus({ ...status, status: e.target.value });
              }
                } >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  ml: -1
                }}
              ></Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default PageEditor;
