import { useState,useRef, useEffect } from 'react';


import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
const axios = require('axios');

import { CSVLink, CSVDownload } from "react-csv";

import { getAllPayments,getAllPaymentforCsv } from '../apiCalls/apiCalls';



const addPlacement = (props) => {
  const [csv, setCsv] = useState([]);
  useEffect(async() => {
    getAllPaymentforCsv().then(async(data) => {
      const headers = ['First Name','Amount','Email','Order Id', 'Payment Id','Title']
      let csvReport=
         data.map((e)=>{return [e.firstName,e.fee,e.email,e.orderId,e.transactionId,e.title]})
         csvReport=[headers,...csvReport]
      await setCsv(csvReport)
    })     
      
      
  }, []);
  return(

  <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >

<Button sx={{ mx: 1 }} onClick={()=>{

     

      }}>  <CSVLink data={csv}>Export</CSVLink>
      </Button>

    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>

      </Card>
    </Box>
  </Box>
);
}

export default addPlacement;
