import { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '../Notification';

import {
  Card,
  Box,
  Button,
  FormGroup,
  TextField,
  CardContent,
  Link
} from '@material-ui/core';
const axios = require('axios');

import { makeStyles } from '@material-ui/styles';
import {
  getCourse,
  getBanner,
  updateCourse,
  updateBanner
} from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  Card: {
    width: 50,
    margin: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const BannerEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();
  const [imageUrl, setImgUrl] = useState('');
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})

  const [banner, setBanner] = useState(
    { banners: [], bannerContent: '', bannerContentBottom: '',bannerImg: '' }
  );
  const { bannerContent,bannerContentBottom, bannerImg, banners } = banner;
  const [randomKey, setRandomKey] = useState(Math.random());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBanner(id).then((data) => {
      setBanner({
        ...banner,
        bannerContent: data.bannerContent,
        bannerContentBottom:data.bannerContentBottom,
        bannerImg: data.bannerImg
      });
      setLoading(false);
    });
  }, [id]);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setBanner({ ...banner, [name]: e.target.value });
  };

  const handleChangeImg = (name) => (e) => {
    e.preventDefault();
    setBanner({
      ...banner,
      [name]: e.target.files[0]
    });
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  };

  const editorRef = useRef(null);
  const bannerUpdateHandler = (e) => {
    e.preventDefault();
    if (tinymce.get('a1').getContent() == '') {
      toast.error("BannerHeader section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('b1').getContent() == '') {
      toast.error("BannerContent section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    const data = {
      bannerContent: tinymce.get('a1').getContent(),
      bannerContentBottom: tinymce.get('b1').getContent(),
    };
    const formData = new FormData();
    if (bannerImg) {
      formData.append('file', bannerImg);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));

    axios({
      method: 'put',
      // url: `http://localhost:9000/api/admin/HomeBanner/edit/${id}`,
      // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/HomeBanner/edit/${id}`,
      url: `https://api.luminartechnolab.com/api/admin/HomeBanner/edit/${id}`,

      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {

        setNotify({
          isOpen: true,
          message:'Updated Successfully',
          type:'success'
        })
        setTimeout(function(){
          navigate('/app/banner');
        }, 3000);
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        alert(response);
      });

  };

  if (loading) {
    return <div>loading</div>;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '50px',
          justifyContent:'flex-start'

        }}
      >
          <h2>Edit Banner</h2>
      </Box>
      <form onSubmit={bannerUpdateHandler}>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <FormGroup className={classes.formGroup}>
          <Card className={classes.card}>
            <CardContent>
              <label>Banner Img</label>
              <Button>
                Choose File
                <input
                  type="file"
                  onChange={handleChangeImg('bannerImg')}
                  key={bannerImg}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top: '0',
                    left: '0'
                  }}
                ></input>
              </Button>
              <img src={imageUrl || bannerImg} className={classes.Card}></img>
              <br />
              <br />
              <label>Banner Content Heading</label>
              <Editor
                id="a1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={banner.bannerContent}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter bannerContent Heading here'
                }}
                name={'bannerContent'}
                value={banner.bannerContent}
                key={`${randomKey}-bannerContent`}

                onChange={handleChange('bannerContent')}
              />
<br/><br/>
              <label>Banner Content Paragraph</label>
              <Editor
                id="b1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={banner.bannerContentBottom}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter bannerContentBottom Heading here'
                }}
                name={'bannerContentBottom'}
                key={`${randomKey}-bannerContentBottom`}
                value={banner.bannerContentBottom}
                onChange={handleChange('bannerContentBottom')}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </CardContent>
          </Card>
        </FormGroup>
      </form>
    </Box>
  );
};

export default BannerEditor;
