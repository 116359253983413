import { useState,useRef, useEffect } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  FormGroup ,
  Grid
} from '@material-ui/core';
import { createFaculty } from '../apiCalls/apiCalls';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateFaculty = () => {
  const navigate = useNavigate();
  const classes = style();

  const [faculty, setFaculty] = useState(
    {
      facultyName:"",
      subject:"",
      linkedInUrl:"",
      googlePlusUrl:"",
      image:"",
      faculties: [],
    }
  );
  const { facultyName,subject,linkedInUrl,googlePlusUrl,faculties,image} = faculty;

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setFaculty({
      ...faculty,
      [name]: e.target.value,
    });
  };
  const facultySubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      facultyName: facultyName,
      subject:subject,
      linkedInUrl:linkedInUrl,
      googlePlusUrl:googlePlusUrl,
      image:image
    };
    createFaculty(data).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      } else {
        let newArray= [...faculties,datas.data]
        setFaculty({
          ...faculty, faculties:newArray
          })
          navigate('/app/faculty')
      }
    });
  };
  return(
    <form  onSubmit={facultySubmitHandler}>
    <div className={classes.container}>
      <Grid item xs={12}>
          <Grid item xs={12}>
          <FormGroup className={classes.formGroup}  >
            <Grid> 
              <Card className={classes.card}>
                <CardContent>
 
                  <TextField        
                     sx={{ maxWidth: 1000 }}
                     fullWidth
                     name={facultyName}
                     value={facultyName}
                     onChange={handleChange('facultyName')}
                     placeholder="Full Name"
                     variant="outlined"
                     required
                  />
                 <br/><br/>
                 <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={subject}
                    value={subject}
                    onChange={handleChange('subject')}
                    placeholder="subject"
                    variant="outlined"
                    required
                  /><br/><br/>
                    <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={linkedInUrl}
                    value={linkedInUrl}
                    onChange={handleChange('linkedInUrl')}
                    placeholder="linkedInUrl"
                    variant="outlined"
                    required
                  /><br/><br/>
                  <TextField
                  sx={{ maxWidth:  1000  }}
                   fullWidth
                  name={googlePlusUrl}
                  value={googlePlusUrl}
                  onChange={handleChange('googlePlusUrl')}
                  placeholder="googlePlusUrl"
                  variant="outlined"
                  required
                /><br/><br/>
                  <TextField
                  sx={{ maxWidth:  1000  }}
                   fullWidth
                  name={image}
                  value={image}
                  onChange={handleChange('image')}
                  placeholder="image Url"
                  variant="outlined"
                  required
                /><br/><br/>
            <Button color="primary" variant="contained" type="submit" >
              Create
            </Button>
                </CardContent>
              </Card> 
            </Grid>
    <br /> <br /> <br />
          </FormGroup>
          </Grid>
      </Grid>
    </div>
    </form>
  )
}

export default CreateFaculty;
