import { useState,useRef, useEffect } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
const axios = require('axios');

import {
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  FormGroup ,
  Divider,
  Grid
} from '@material-ui/core';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateGallery = () => {
  const navigate = useNavigate();
  const classes = style();

  const [gallery, setGallery] = useState(
    {
      title:"",
      image:"image",
      galleries: [],
    }
  );
  const { title,galleries,image} = gallery;

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setGallery({
      ...gallery,
      [name]: e.target.value,
    });
  };

  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setGallery({
      ...gallery,
      [name]:  e.target.files[0]
    });
  };

  const testimonySubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      title: title,
    };
    const formData = new FormData();

    if (image) {
      formData.append("file", image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));


    axios({
      method: "post",
      // url: "http://localhost:9000/api/admin/gallery/create",
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/testimonial/create",
      url: "https://api.luminartechnolab.com/api/admin/gallery/create",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function(response){

      setGallery({...gallery,
        title:"",
        image:null,
        galleries: [],
      })
      navigate(`/app/gallery`)

    }).catch(function(response){
      console.log("err",response)
    })
  };
  return(
    <form  onSubmit={testimonySubmitHandler}>
    <div className={classes.container}>
      <Grid item xs={12}>
          <Grid item xs={12}>
          <FormGroup className={classes.formGroup}  >
            <Grid> 
              <Card className={classes.card}>
                <CardContent>
                 <label>Title</label>
                  <TextField        
                     sx={{ maxWidth: 1000 }}
                     fullWidth
                     name={'title'}
                     value={title}
                     onChange={handleChange('title')}
                     placeholder="Title"
                     variant="outlined"
                     required
                  />
                 <br/><br/>
                <label>Image</label>
                  <input type='file' 
                  onChange={handleChangeImg('image')}
                  name={'image'}
                  key={'image'}
                  >
                  </input>
                  <br /> <br /> <br />
                  <label>Course</label>
                  <br/> <br />
            <Button color="primary" variant="contained" type="submit" >
              Create
            </Button>
                </CardContent>
       </Card> 
     </Grid>
    <br /> <br /> <br />
          </FormGroup>
          </Grid>
      </Grid>
    </div>
    </form>
  )
}

export default CreateGallery;
