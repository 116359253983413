import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TablePagination,
  TableRow,
  Grid,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/styles';

import {deleteTrainers,getAllTrainers} from '../apiCalls/apiCalls';
const style = makeStyles({
  titleItemRight: {
    color: "white",
    backgroundColor: "blue",
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
  titleBar :{
   padding : "30px"
  },
  Card: {
    width: 50,
    margin: 'auto'
  },
  Media: {
    height: '100%',
    width: '100%'
  }
});
const Trainer = () => {

  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(0);

  const [page, setPage] = useState(1);
  const classes = style();
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
  const [trainer, setTrainer] = useState([{ trainers: [], }]);
  const { trainers } = trainer;
  const navigate = useNavigate();

  useEffect(() => {
    getAllTrainers().then((data) => {
      setTrainer({
        ...trainer,
        trainers: data.data
      });
      setLimit(data.TotalNoPages)

    });

  }, []);
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllTrainers(pageNumber)
    .then((data) => {
      if (data) {
        setTrainer({
          ...trainer,
          trainers: data.data
        });
        setLimit(data.TotalNoPages)

      } else {
        alert(data.message);
      }
    })
    .catch((err) => {
      alert(err.message);
    });
  };
  const allListHandler = () => {
    getAllTrainers()
      .then((data) => {
        if (data) {
          setTrainer({
            ...trainer,
            trainers: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deleteTrainers(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };

  return (
    <Card       sx={{
      minHeight: '100%',
      py: 3
    }}>
      <PerfectScrollbar>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
        <Box >
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  placeholder="name"
                  variant="outlined"
                  required
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CreatedAt</TableCell>
                <TableCell>FullName</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>FacebookLink</TableCell>
                <TableCell>YoutbubeLink</TableCell>
                <TableCell>LinkedInLink</TableCell>
                <TableCell>InstagramLink</TableCell>

                <TableCell>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {trainers && trainers.filter((val)=>{
                  if(search==""){
                    return val
                  }else if(search){
                    if(val.name){
                      if(val.name.toLowerCase().includes(search.toLocaleLowerCase())){
                        return val
                      }
                    }
                  }
                }).map((customer,i) =>{
                    return (
                    <TableRow hover key={i}>
                        <TableCell>
                          {moment(customer.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>{customer.name}</TableCell>
                        <TableCell>{customer.subject}</TableCell>
                        <TableCell>{customer.facebookLink}</TableCell>
                        <TableCell>{customer.youtubeLink}</TableCell>
                        <TableCell>{customer.linkedInLink}</TableCell>
                        <TableCell>{customer.instagramLink}</TableCell>

                        <TableCell>
                          <img src={`${customer.image}`} className={classes.Card}></img>
                          </TableCell>
                        <TableCell>
                        <Link to="#">

                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removePageHandler(customer._id)
                                }
                              }) 
                            }}
                          />
                             </Link>

                          <Link to={`/app/editTrainer/${customer._id}`}>
                            {' '}
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                          </Link>
                        </TableCell>
                      </TableRow> 
                   );
                  })} 
            </TableBody>
          </Table>
        </Box>
        
      </PerfectScrollbar>


      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination color="primary" count={limit} size="small" page={page} onChange={handleChange} />
      </Box>
    </Card>
  );
};

// PageListResults.propTypes = {
//   customers: PropTypes.array.isRequired
// };

export default Trainer;
