import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PaymentList from '../components/payment/PaymentList';
import AddPlacement from '../components/payment/addPlacement'
import PlacementSearch from 'src/components/placement/PlacementSearch'; 
const Placement = () => (
  <>
    <Helmet>
      <title>Luminar Technolab</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <AddPlacement />
        <Box sx={{ minWidth: 1050 }}>
          <PaymentList />
        </Box>
      </Container>
    </Box>
  </>
);

export default Placement;
