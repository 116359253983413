import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TablePagination,
  TableRow,
  Chip,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/styles';


import { getAllCourses,getAllBanners, deleteCourse,deleteBanner } from '../apiCalls/apiCalls';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
const style = makeStyles({
  titleItemRight: {
    color: "white",
    backgroundColor: "blue",
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
  titleBar :{
   padding : "30px"
  },
  Card: {
    width: 50,
    margin: 'auto'
  },
  Media: {
    height: '100%',
    width: '100%'
  }
});
const Courses = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const classes = style();
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
  const [limit, setLimit] = useState(0);
  const [banner, setBanner] = useState([{ banners: [] }]);
  const { banners } = banner;
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  useEffect(() => {
    getAllBanners().then((data) => {
      setBanner({
        ...banner,
        banners: data.data
      });
      setLimit(data.TotalNoPages)

    });
  }, []);
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllBanners(pageNumber)
      .then((data) => {
        if (data) {
          setBanner({
            ...banner,
            banners: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const allListHandler = () => {
    getAllBanners()
      .then((data) => {
        if (data) {
          setBanner({
            ...banner,
            banners: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deleteBanner(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };

  return (
    <Card
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
      <PerfectScrollbar>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
        <Box>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  placeholder="name"
                  variant="outlined"
                  required
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>bannerContentHeading</TableCell>
              <TableCell>bannerContentParagraph</TableCell>

                <TableCell>bannerImage</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners &&
                banners.map((customer, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>{customer.bannerContent}</TableCell>
                        <TableCell>{customer.bannerContentBottom}</TableCell>

                        <img src={`${customer.bannerImg}`} className={classes.Card}></img>
                        <TableCell>
                        <Link to="#">

                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removePageHandler(customer._id)
                                }
                              }) 
                            }}
                          />
                            </Link>
                          <Link to={`/app/editBanner/${customer._id}`}>
                            {' '}
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination
          color="primary"
          count={limit}
          size="small"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Card>
  );
};

// PageListResults.propTypes = {
//   customers: PropTypes.array.isRequired
// };

export default Courses;
