import { useState, useEffect } from 'react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import * as Icon from 'react-feather';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  CardContent,
  TableRow,
  TextField,
  SvgIcon,
  InputAdornment,
  Button,
  Chip
} from '@material-ui/core';
import { getAllPages, deletePage,filterPage } from '../apiCalls/apiCalls';
import PageEditor from './PageEditor';
import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import Page from 'src/pages/Page';

const style = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 90,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon:{
    left:7
  },
  // iconOpen:{
  //   transform:'none'
  // },
  formControlLabel:{
    left:24
  },
  selectSelect:{
    paddingLeft:'24px'
  }
}));

const PageListResults = () => {
  const classes = style();
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [option, setOption] = useState('');
  const [filterPagination, setFilterPagination] = useState('All');

  const [page, setPage] = useState([{ pages: [] }]);
  const { pages } = page;
  const navigate = useNavigate();

  useEffect(() => {
    getAllPages().then((data) => {
      setPage({
        ...page,
        pages: data
      });
    });
  }, []);


  const allListHandler = () => {
    getAllPages()
      .then((data) => {
        if (data) {
          setPage({
            ...page,
            pages: data
          });
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (id) => {
    await deletePage(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        allListHandler();
      }
    });
  };
  const handleChange = (event, pageNumber) => {
    setPageNumber(pageNumber);
    if(filterPagination==='All'){
    getAllPages(pageNumber)
      .then((data) => {
        if (data) {
          setPage({
            ...page,
            pages: data
          });
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    }
    else if(filterPagination==='Active'){
       filterPage(true,pageNumber) // API call
      .then((data) => {
        setPage({
          ...page, 
          pages: data
        });
      })
    }
    else if(filterPagination==='inActive'){
      filterPage(false,pageNumber) // API call
      .then((data) => {
        setPage({
          ...page,
          pages: data
        });
      })
    }
  };

  const filterHandleChange = (event) => {
    setOption(event.target.value);
  };

  const filterActivePageHandler = () => {
    setPageNumber(1)
    return filterPage(true) // API call
    .then((data) => {
      setPage({
        ...page, 
        pages: data
      });
      setFilterPagination('Active')
    })
}

  const filterInactivePageHandler = () => {
    setPageNumber(1)

    return filterPage(false) // API call
      .then((data) => {
        setPage({
          ...page,
          pages: data
        });
        setFilterPagination('inActive')
      
      })
  }



  return (
    <Card>
      <Box>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <TextField
                sx={{ maxWidth: 500 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
                placeholder="title"
                variant="outlined"
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              
              <FormControl 
              className={classes.formControl}
              > 
                <InputLabel 
                //classes={{formControl:classes.formControlLabel}}
                 id="demo-simple-select-label">
                  {/* <SvgIcon fontSize="small" color="#5664d2">
                  <Icon.Filter /> 
                  </SvgIcon> */}Status
                </InputLabel>
                <Select IconComponent={undefined}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={option}
                  label="option"
                  onChange={filterHandleChange}
                 // classes={{icon:classes.icon, iconOpen:classes.iconOpen,select:classes.selectSelect}}
                  
                >
                  <MenuItem ></MenuItem>
                  <MenuItem value={10}  onClick={handleChange}>All</MenuItem>

                  <MenuItem value={true}  onClick={filterActivePageHandler}>Active
                  </MenuItem>
                  <MenuItem value={false} onClick={filterInactivePageHandler}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pages &&
                pages
                  .filter((val) => {
                    if (search == '') {
                      return val;
                    } else if (search) {
                      if (val.title) {
                        if (
                          val.title
                            .toLowerCase()
                            .includes(search.toLocaleLowerCase())
                        ) {
                          return val;
                        }
                      }
                    }
                  })
                  .map((customer, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>{customer.title} </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={customer.status ? 'Active' : 'InActive'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          {moment(customer.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(customer.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => removePageHandler(customer._id)}
                          />
                          <Link to={`/app/pageEditor/${customer._id}`}>
                            {' '}
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination
          color="primary"
          count={2}
          size="small"
          page={pageNumber}
          onChange={handleChange}
        />
      </Box>
    </Card>
  );
};

export default PageListResults;
