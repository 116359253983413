import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Header from '../components/home/BannerHeader';
import CreateHome from 'src/components/home/CreateHome';
const Home = () => (
  <>
    <Helmet>
      <title>Luminar Technolab</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>

<Header/>
        <CreateHome />
        {/* <Box sx={{ minWidth: 1050 }}>
          <Course  />
        </Box> */}
      </Container>
    </Box>
  </>
);

export default Home;
