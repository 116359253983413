import { useState,useRef, useEffect } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
const axios = require('axios');

import {
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  FormGroup ,
  Divider,
  Grid
} from '@material-ui/core';
import { createTestimonial } from '../apiCalls/apiCalls';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateTestimony = () => {
  const navigate = useNavigate();
  const classes = style();

  const [testimonial, setTestimonial] = useState(
    {
      fullName:"",
      designation:"",
      company:"",
      Testimony:"",
      course:"",
      image:"image",
      testimonials: [],
    }
  );
  const { fullName,designation,company,Testimony,course ,testimonials,image} = testimonial;

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setTestimonial({
      ...testimonial,
      [name]: e.target.value,
    });
  };

  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setTestimonial({
      ...testimonial,
      [name]:  e.target.files[0]
    });
  };

  const testimonySubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      fullName: fullName,
      course:course,
      designation:designation,
      company:company,
      testimony:Testimony,
    };
    const formData = new FormData();

    if (image) {
      formData.append("file", image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));


    axios({
      method: "post",
      // url: "http://localhost:9000/api/admin/testimonial/create",
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/testimonial/create",
      url: "https://api.luminartechnolab.com/api/admin/testimonial/create",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function(response){

      setTestimonial({...testimonial,
        fullName:"",
        designation:"",
        company:"",
        Testimony:"",
        course:"",
        image:null,
        testimonials: [],
      })
      navigate(`/app/testimonials`)

    }).catch(function(response){
      console.log("err",response)
    })


    // createTestimonial(data).then((datas) => {
    //   console.log("name",data)
    //   if (datas.name === "Error") {
    //     alert(datas.message);
    //   } else {
    //     let newArray= [...testimonials,datas.data]
    //     setTestimonial({
    //       ...testimonial, testimonials:newArray
    //       })
    //       navigate('/app/testimonials')
    //   }
    // });
  };
  return(
    <form  onSubmit={testimonySubmitHandler}>
    <div className={classes.container}>
      <Grid item xs={12}>
          <Grid item xs={12}>
          <FormGroup className={classes.formGroup}  >
            <Grid> 
              <Card className={classes.card}>
                <CardContent>
                 <label>Full Name</label>
                  <TextField        
                     sx={{ maxWidth: 1000 }}
                     fullWidth
                     name={'fullName'}
                     value={fullName}
                     onChange={handleChange('fullName')}
                     placeholder="Full Name"
                     variant="outlined"
                     required
                  />
                 <br/><br/>
                 <label>Designation</label>

                 <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'designation'}
                    value={designation}
                    onChange={handleChange('designation')}
                    placeholder="Designation"
                    variant="outlined"
                    required
                  /><br/><br/>
                    <label>Company</label>

                    <TextField
                     sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'company'}
                    value={company}
                    onChange={handleChange('company')}
                    placeholder="Company"
                    variant="outlined"
                    required
                  /><br/><br/>
                  <label>Testimony</label>

                  <TextField
                  sx={{ maxWidth:  1000  }}
                   fullWidth
                  name={'Testimony'}
                  value={Testimony}
                  onChange={handleChange('Testimony')}
                  placeholder="Testimony"
                  variant="outlined"
                  required
                /><br/><br/>
                <label>Image</label>
                  <input type='file' 
                  onChange={handleChangeImg('image')}
                  name={'image'}
                  key={'image'}
                  >
                  </input>
                  <br /> <br /> <br />
                  <label>Course</label>

                 <TextField
                  sx={{ maxWidth:  1000  }}
                  fullWidth
                  name={'course'}
                  value={course}
                  onChange={handleChange('course')}
                  placeholder="Course Done"
                  variant="outlined"
                  required
               /><br/><br/> <br />
            <Button color="primary" variant="contained" type="submit" >
              Create
            </Button>
                </CardContent>
       </Card> 
     </Grid>
    <br /> <br /> <br />
          </FormGroup>
          </Grid>
      </Grid>
    </div>
    </form>
  )
}

export default CreateTestimony;
