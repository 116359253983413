import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Card,
  Box,
  Button,
  FormGroup,
    TextField,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getFaculty, updateFaculty } from '../apiCalls/apiCalls';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const FacultyEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();
  const [facultyName, setFacultyName] = useState('');
  const [subject, setSubject] = useState('');
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [googlePlusUrl, setGooglePlusUrl] = useState('');
  const [image, setImage] = useState('');

  const [status, setStatus] = useState(false);

  useEffect(() => {
    getFaculty(id).then((data) => {
      setFacultyName({ ...facultyName, facultyName: data.facultyName });
      setSubject({ ...subject, subject: data.subject });
      setLinkedInUrl({ ...linkedInUrl, linkedInUrl: data.linkedInUrl });
      setGooglePlusUrl({ ...googlePlusUrl, googlePlusUrl: data.googlePlusUrl });
      setImage({ ...image, image: data.image });
      setStatus({ ...status, status: data.status });
    });
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setFacultyName({ ...facultyName, [name]: e.target.value });
    setSubject({ ...subject, [name]: e.target.value });
    setLinkedInUrl({ ...linkedInUrl, [name]: e.target.value });
    setGooglePlusUrl({ ...googlePlusUrl, [name]: e.target.value });
    setImage({ ...image, [name]: e.target.value });
    setStatus({ ...status, [name]: e.target.value });
  };


  const pageUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
      facultyName:facultyName.facultyName,
      subject : subject.subject,
      linkedInUrl : linkedInUrl.linkedInUrl,
      googlePlusUrl : googlePlusUrl.googlePlusUrl,
      image : image.image,
    };
    updateFaculty(data,id).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      } else {
          navigate('/app/faculty')
      }
    });
  };
  return (


    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
        <form onSubmit={pageUpdateHandler} >
            <FormGroup className={classes.formGroup}  >
              <Card  className={classes.card}>
                <CardContent >
              <TextField 
                fullWidth
                margin="normal"
                name={facultyName}
                onChange={handleChange('facultyName')}
                value={facultyName.facultyName}
                placeholder = "Faculty name"
                variant="outlined"
                required
              />
              <TextField 
                fullWidth
                margin="normal"
                name={subject}
                onChange={handleChange('subject')}
                value={subject.subject}
                variant="outlined"
                placeholder = "Subject"
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name={linkedInUrl}
                onChange={handleChange('linkedInUrl')}
                value={linkedInUrl.linkedInUrl}
                variant="outlined"
                placeholder = "linkedInUrl"
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name={googlePlusUrl}
                onChange={handleChange('googlePlusUrl')}
                value={googlePlusUrl.googlePlusUrl}
                variant="outlined"
                placeholder = "googlePlusUrl"
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name={image}
                onChange={handleChange('image')}
                value={image.image}
                variant="outlined"
                placeholder = "image"
                required
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
              </CardContent> 
            </Card>
            </FormGroup>
        </form>
    </Box>

  );
};

export default FacultyEditor;
