import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Trainers from './pages/trainers';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Testimonials from './pages/Testimonials';
import Course from './pages/Course';
import Home from './pages/Home';
import Placement from './pages/Placement';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Payment from './pages/Payment'
import Faculty from './pages/Faculty';
import Enquiry from './pages/Enquiry';
import EnquiryForms from './pages/EnquiryForms';
import Page from './pages/Page';
import JoinNow from './pages/JoinNow';
import BookADemo from './pages/BookADemo';
import Banner from './pages/Banner';
import GalleryList from './pages/Gallery';
import BannerEditor from './components/home/editBanner';

import PageEditor from './components/page/PageEditor';
import AddPlacement from './components/placement/addPlacement';
import CreateGallery from './components/gallery/CreateGallery';
import PlacementEditor from './components/placement/PlacementEditor';
import CreateTestimonial from './components/testimonials/CreateTestimonial';
import CreateFaculty from './components/faculty/CreateFaculty';
import FacultyEditor from './components/faculty/editFaculty';
import TestimonialEditor from './components/testimonials/editTestimonial';
import CreateCourse from './components/course/CreateCourse';
import CourseEditor from './components/course/editCourse';
import AddModules from './components/course/addModules';
import ListModules from './components/course/listModules';
import CreateHome from './components/home/CreateHome';
import ModuleEditor from './components/course/editModule'
import CreateTrainer from './components/trainers/CreateTrainer';
import EditTrainer from './components/trainers/editTrainers';
import AddBanner from './components/home/Banner';
import GalleryEditor from './components/gallery/editGallery';
const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard authorized={true}/> },
      { path: 'products', element: <ProductList /> },
      { path: 'testimonials', element: <Testimonials /> },
      { path: 'course', element: <Course /> },
      { path: 'home', element: <Home /> },
      { path: 'faculty', element: <Faculty /> },
      { path: 'placement', element: <Placement /> },
      { path: 'joinNow', element: <JoinNow /> },
      { path: 'banner', element: <Banner /> },
      { path: 'bookADemo', element: <BookADemo /> },
      { path: 'gallery', element: <GalleryList /> },

      { path: 'trainers', element: <Trainers /> },
      { path: 'payment', element: <Payment /> },
      { path: 'pages', element: <Page /> },
      { path: 'settings', element: <Settings /> },
      { path: 'enquiry', element: <Enquiry /> },
      { path: 'contactForm', element: <EnquiryForms /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'pageEditor/:id',element: <PageEditor /> },
      { path: 'placementEditor/:id',element: <PlacementEditor /> },
      { path: 'addPlacement',element: <AddPlacement /> },
      { path: 'addGallery',element: <CreateGallery/> },

      {path: 'createTestimonial' ,element: <CreateTestimonial />  },
      {path: 'createCourse' ,element: <CreateCourse />  },
      {path: 'createFaculty' ,element: <CreateFaculty />  },
      {path: 'editFaculty/:id' ,element: <FacultyEditor />  },
      {path: 'createtrainer' ,element: <CreateTrainer />  },
      {path: 'editTrainer/:id' ,element: <EditTrainer />  },
      {path: 'createBanner' ,element: <AddBanner />  },
      {path: 'editBanner/:id' ,element: <BannerEditor/>    },
      {path: 'editTestimonial/:id' ,element: <TestimonialEditor />  },
      {path: 'editCourse/:id' ,element: <CourseEditor />  },
      {path: 'addModules/:id' ,element: <AddModules />  },
      {path: 'editModule/:courseId/:id' ,element: <ModuleEditor />  },
      {path: 'editGallery/:id' ,element: <GalleryEditor />  },

      {path:'listModules/:id',element:<ListModules/>}

    ]
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'login', element:<Login/> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Login/> },
      { path: 'pages', element: <Navigate to="/app/pages" /> },
      { path: 'placement', element: <Navigate to="/app/placement" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
