import { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios');

import {
  Card,
  CardContent,
  TextField,
  Button,
  FormGroup,
  Grid
} from '@material-ui/core';
import { createCourse } from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  card: {
    width: '1000px',
    height: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateCourse = () => {
  const navigate = useNavigate();
  const classes = style();
  const [header, setHeader] = useState('');
  const [course, setCourse] = useState({
    title: '',
    metaTitle: '',
    metaDescription: '',
    description: '',
    nextBatch: '',
    nextBatchCalicut: '',
    nextBatchTvm:'',
    mode: '',
    duration: '',
    fee: '',
    onlineFee: '',
    order: '',
    RegistrationFee: '',
    certification: '',
    bannerHeading: '',
    bannerContent: '',
    detailPageHeading: '',
    certificationSection: '',
    whyLearnCourse: '',
    howWeHelp: '',
    sliderImg: 'sliderImg',
    extraContent: '',
    icon: 'icon',
    detailsPageImg1: 'detailsPageImg1',
    detailsPageImg2: 'detailsPageImg2',
    url: '',
    courses: []
  });
  const {
    title,
    nextBatch,
    nextBatchCalicut,
    nextBatchTvm,
    mode,
    sliderImg,
    detailsPageImg1,
    detailsPageImg2,
    extraContent,
    icon,
    duration,
    fee,
    onlineFee,
    RegistrationFee,
    certification,
    detailPageHeading,
    bannerHeading,
    bannerContent,
    certificationSection,
    whyLearnCourse,
    howWeHelp,
    courses,
    metaTitle,
    metaDescription,
    url,
    description,
    order
  } = course;
  const editorRef = useRef(null);

  const handleChangeImg = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
  };
  const handleChangeImg2 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
  };
  const handleChangeImg3 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
  };

  const handleChangeImg1 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
  };

  const handleChange = (name) => (e) => {
    e.preventDefault();
    // if (editorRef.current) {
    //   console.log("a",editorRef.current.getContent());
    // }
    setCourse({
      ...course,
      [name]: e.target.value
    });
  };

  const courseSubmitHandler = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      console.log('q1', tinymce.get('a1').getContent());
      console.log('q2', tinymce.get('a2').getContent());
      console.log('q3', tinymce.get('a3').getContent());
    }
    if (tinymce.get('b1').getContent() == '') {
      toast.error("Banner Header required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('b2').getContent() == '') {
      toast.error("Banner Content required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a2').getContent() == '') {
      toast.error("Certification section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a3').getContent() == '') {
      toast.error("WhyLearnCourse section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a4').getContent() == '') {
      toast.error("HowWeHelp section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    const data = {
      title: title,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      description: description,
      nextBatch: nextBatch,
      nextBatchCalicut: nextBatchCalicut,
      nextBatchTvm:nextBatchTvm,
      mode: mode,
      duration: duration,
      fee: fee,
      onlineFee: onlineFee,
      RegistrationFee: RegistrationFee,
      url: url,
      certification: certification,
      bannerHeading: tinymce.get('b1').getContent(),
      bannerContent: tinymce.get('b2').getContent(),
      detailPageHeading: tinymce.get('a1').getContent(),
      certificationSection: tinymce.get('a2').getContent(),
      whyLearnCourse: tinymce.get('a3').getContent(),
      howWeHelp: tinymce.get('a4').getContent(),
      extraContent: tinymce.get('a5').getContent(),
      order: order
    };
    const formData = new FormData();
    if (sliderImg) {
      formData.append('file', sliderImg);
    }
    if (icon) {
      formData.append('icon', icon);
    }
    if (detailsPageImg1) {
      formData.append('detailsPageImg1', detailsPageImg1);
    }
    if (detailsPageImg2) {
      formData.append('detailsPageImg2', detailsPageImg2);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    axios({
      method: 'post',
      // url: 'http://localhost:9000/api/admin/course/create',
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/course/create",
      url: 'https://api.luminartechnolab.com/api/admin/course/create',

      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (datas) {
        let newArray = [...courses, datas.data];
        setCourse({
          ...course,
          courses: newArray
        });

        navigate('/app/course');
      })
      .catch(function (response) {
        //handle error
      });
  };

  return (
    <form onSubmit={courseSubmitHandler}>
      <div className={classes.container}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormGroup className={classes.formGroup}>
              <Grid>
                <Card className={classes.card}>
                  <CardContent>
                    <h2>Create Course</h2>
                    <label>Title</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'title'}
                      value={title}
                      onChange={handleChange('title')}
                      placeholder="Title"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Meta Title</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'metaTitle'}
                      value={metaTitle}
                      onChange={handleChange('metaTitle')}
                      placeholder="Meta Title"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Meta Description</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'metaDescription'}
                      value={metaDescription}
                      onChange={handleChange('metaDescription')}
                      placeholder="Meta Description"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Description</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'description'}
                      value={description}
                      onChange={handleChange('description')}
                      placeholder="description"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Seo Url</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'url'}
                      value={url}
                      onChange={handleChange('url')}
                      placeholder="seo url"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>NextBatch In Cochin</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'nextBatch'}
                      value={nextBatch}
                      onChange={handleChange('nextBatch')}
                      placeholder="NextBatch"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>NextBatch In Calicut</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'nextBatchCalicut'}
                      value={nextBatchCalicut}
                      onChange={handleChange('nextBatchCalicut')}
                      placeholder="NextBatch"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>NextBatch In Trivandrum</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'nextBatchTvm'}
                      value={nextBatchCalicut}
                      onChange={handleChange('nextBatchTvm')}
                      placeholder="NextBatch"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Mode</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'mode'}
                      value={mode}
                      onChange={handleChange('mode')}
                      placeholder="Mode"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Duration</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'duration'}
                      value={duration}
                      onChange={handleChange('duration')}
                      placeholder="Duration"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Fees (EMI Mode)</label>
                    <TextField
                      type="text"
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'fee'}
                      value={fee}
                      pattern="\d*"
                      onChange={handleChange('fee')}
                      placeholder="Course fee (*Numbers only)"
                      variant="outlined"
                      required
                    />{' '}
                    <br /> <br />
                    <label>Fees (One time Payment mode)</label>
                    <TextField
                      type="text"
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'onlineFee'}
                      value={onlineFee}
                      pattern="\d*"
                      onChange={handleChange('onlineFee')}
                      placeholder="Course fee (*Numbers only)"
                      variant="outlined"
                      required
                    />
                    <label>RegistrationFee</label>
                    <TextField
                      type="text"
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'RegistrationFee'}
                      value={RegistrationFee}
                      pattern="\d*"
                      onChange={handleChange('RegistrationFee')}
                      placeholder="Course Registration Fee (*Numbers only)"
                      variant="outlined"
                      required
                    />{' '}
                    <br /> <br />
                    <label>Order</label>
                    <TextField
                      type="text"
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'order'}
                      value={order}
                      pattern="\d*"
                      onChange={handleChange('order')}
                      placeholder="Course Registration Fee (*Numbers only)"
                      variant="outlined"
                      required
                    />{' '}
                    <br /> <br />
                    <label>Certification</label>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'certification'}
                      value={certification}
                      onChange={handleChange('certification')}
                      placeholder="certification"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <label>Slider Image</label>
                    <input
                      type="file"
                      onChange={handleChangeImg('sliderImg')}
                      name={'sliderImg'}
                      key={'sliderImg'}
                    ></input>
                    <br />
                    <br />
                    <label>Banner Heading</label>
                    <Editor
                      id="b1"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={bannerHeading}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'preview',
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'preview' +
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter Banner Heading  here'
                      }}
                      name={'bannerHeading'}
                      value={bannerHeading}
                      onChange={handleChange('bannerHeading')}
                    />
                    <label>Banner Content</label>
                    <Editor
                      id="b2"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={bannerContent}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'preview',
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'preview' +
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter Banner Content  here'
                      }}
                      name={'bannerContent'}
                      value={bannerContent}
                      onChange={handleChange('bannerContent')}
                    />
                    <label>Icon</label>
                    <input
                      type="file"
                      onChange={handleChangeImg1('icon')}
                      name={'icon'}
                      key={'icon'}
                    ></input>
                    <br />
                    <br />
                    <label>DetailPageHeading</label>
                    <Editor
                      id="a1"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={detailPageHeading}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'preview',

                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'preview' +
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter CourseDetailPage Heading here'
                      }}
                      name={'detailPageHeading'}
                      value={detailPageHeading}
                      onChange={handleChange('detailPageHeading')}
                    />
                    <br /> <br />
                    <label>Certification Section</label>
                    <Editor
                      id="a2"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p>This is the initial content of the CertificationSection page heading.</p>"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter CourseDetailPage Heading here'
                      }}
                      name={'certificationSection'}
                      value={certificationSection}
                      onChange={handleChange('certificationSection')}
                    />{' '}
                    <br /> <br />
                    <label>whyLearnCourse</label>
                    <Editor
                      id="a3"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p>This is the initial content of the whyLearnCourse page heading.</p>"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter CourseDetailPage Heading here'
                      }}
                      name={'whyLearnCourse'}
                      value={whyLearnCourse}
                      onChange={handleChange('whyLearnCourse')}
                    />
                    <label>howWeHelp</label>
                    <Editor
                      id="a4"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p>This is the initial content of the whyLearnCourse page heading.</p>"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter CourseDetailPage Heading here'
                      }}
                      name={'howWeHelp'}
                      value={howWeHelp}
                      onChange={handleChange('howWeHelp')}
                    />
                    <label>Extra Content</label>
                    <Editor
                      id="a5"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p>This is the initial content of the extraContent page heading.</p>"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        placeholder: 'Enter extraContent Heading here'
                      }}
                      name={'extraContent'}
                      value={extraContent}
                      onChange={handleChange('extraContent')}
                    />
                    <br />
                    <br />
                    <label>CourseDetailsPageImage1</label>
                    <input
                      type="file"
                      onChange={handleChangeImg2('detailsPageImg1')}
                      name={'detailsPageImg1'}
                      key={'detailsPageImg1'}
                    ></input>
                    <br />
                    <br />
                    <label>CourseDetailsPageImage2</label>
                    <input
                      type="file"
                      onChange={handleChangeImg3('detailsPageImg2')}
                      name={'detailsPageImg2'}
                      key={'detailsPageImg2'}
                    ></input>
                    <Button color="primary" variant="contained" type="submit">
                      Create
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <br /> <br /> <br />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default CreateCourse;
