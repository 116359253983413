import { useState,useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Box,
  Button,
  FormGroup,
  TextField,
  CardContent,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getModuleById, updateModule } from '../apiCalls/apiCalls';
import Notification from '../Notification';

const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const ModuleEditor = () => {
  const classes = style();

  const { courseId,id } = useParams();
  const navigate = useNavigate();
  const [moduleTitle, setmoduleTitle] = useState('');
  const [moduleName, setmoduleName] = useState('');
  const [description, setDescription] = useState('');
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})

  useEffect(() => {
    getModuleById(courseId,id).then((data) => {
      setmoduleTitle({ ...moduleTitle, moduleTitle: data[0].moduleTitle });
      setmoduleName({ ...moduleName, moduleName: data[0].moduleName });
      setDescription({ ...description, description: data[0].description });

    })
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setmoduleTitle({ ...moduleTitle, [name]: e.target.value });
    setmoduleName({ ...moduleName, [name]: e.target.value });
    setDescription({ ...description, [name]: e.target.value });
  };
  const editorRef = useRef(null);
  const courseUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
      moduleTitle: moduleTitle.moduleTitle,
      moduleName:moduleName.moduleName,
      description:description.description
    };
    updateModule(data,id,courseId).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Updated Successfully',
          type:'success'
        })
        setTimeout(function(){
          navigate(`/app/listModules/${courseId}`)
}, 3000);
      }
    });
  };

  return (


    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    > 
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        padding:'35px'
      }}
    >
    <h2>Edit Module</h2>
      </Box>
              <form onSubmit={courseUpdateHandler} >
              <Notification
        notify={notify}
        setNotify={setNotify}
        />
            <FormGroup className={classes.formGroup}  >
              <Card  className={classes.card}>
                <CardContent >
              <TextField 
                fullWidth
                margin="normal"
                name={moduleTitle}
                onChange={handleChange('moduleTitle')}
                value={moduleTitle.moduleTitle}
                variant="outlined"
                placeholder="Module Title"
                required
              />
              <TextField 
                fullWidth
                margin="normal"
                name={moduleName}
                onChange={handleChange('moduleName')}
                value={moduleName.moduleName}
                variant="outlined"
                placeholder="Module Name"
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name={description}
                onChange={handleChange('description')}
                value={description.description}
                variant="outlined"
                placeholder="description"
                required
              />

              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
              </CardContent> 
            </Card>
            </FormGroup>
        </form>
    </Box>

  );
};

export default ModuleEditor;
