import { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { makeStyles } from '@material-ui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardContent,
  TextField,
  Button,
  FormGroup,
  Grid
} from '@material-ui/core';
import { createHome, getHome,updateHome } from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  card: {
    width: '1000px',
    height: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CreateHome = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');

  const classes = style();
const [header,setHeader]=useState('')
  const [home, setHome] = useState({
    videoUrl: '',
    videoDescription:'',
    homes: []
  });

  const [randomKey, setRandomKey] = useState(Math.random());

  useEffect(() => {
    getHome().then((data) => {
      setHome({
        ...home,
        videoUrl: data[0].videoUrl,
        videoDescription:data[0].videoDescription
      });
      setId(data[0]._id)
    });
  }, []);

  const { videoUrl, videoDescription,homes } = home;
  const editorRef = useRef(null);

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setHome({
      ...home,
      [name]: e.target.value
    });
  };
  const courseSubmitHandler = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      console.log("q1",tinymce.get("a1").getContent());
    }
    if (tinymce.get('a1').getContent() == '') {
      toast.error("Video Description required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    const data = {
      videoUrl: videoUrl,
      videoDescription:tinymce.get("a1").getContent(),
    };
    updateHome(data,id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        let newArray = [...homes, datas.data];
        setHome({
          ...home,
          homes: newArray
        });
        toast.success("Successfully updated", {
          position: "top-center",
          autoClose: 2000,
      },)
        navigate('/app/home');
      }
    });
  };

  return (
    <form onSubmit={courseSubmitHandler}>
      <div className={classes.container}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormGroup className={classes.formGroup}>
              <Grid>
                <Card className={classes.card}>
                  <CardContent>
                    <TextField
                      sx={{ maxWidth: 1000 }}
                      fullWidth
                      name={'videoUrl'}
                      value={videoUrl}
                      onChange={handleChange('videoUrl')}
                      placeholder="Video Url"
                      variant="outlined"
                      required
                    />
                    <br />
                    <br />
                    <Editor id="a1"
                      onInit={(evt, editor) => (editorRef.current = editor)} 
                      initialValue={videoDescription}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          placeholder:'Enter Video details  here',

                      }}
                      name={'videoDescription'}
                      key={`${randomKey}-videoDescription`}

                      value={videoDescription}
                      onChange={handleChange('videoDescription')}
                    />
                    <br /> <br />

                    <Button color="primary" variant="contained" type="submit">
                      Update
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <br /> <br /> <br />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default CreateHome;
