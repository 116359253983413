import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Course from '../components/course/CourseCard';
import CourseHeader from '../components/course/CourseHeader';
const Courses = () => (
  <>
    <Helmet>
      <title>Luminar Technolab</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <CourseHeader />
        <br/>
        <Box sx={{ minWidth: 1050 }}>
          <Course  />
        </Box>
      </Container>
    </Box>
  </>
);

export default Courses;
