import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';
import Notification from '../Notification';
// import Select from "react-select";
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Card,
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment ,
  SvgIcon, 
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getAllCourses, getPlacement,updatePlacement } from '../apiCalls/apiCalls';
import Page from 'src/pages/Page';
import { color, fontWeight } from '@mui/system';
const axios = require('axios');
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  Card1: {
    width: 50,
    margin: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const PlacementEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();
  const [imageUrl,setImgUrl]=useState('')
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [courseList,setCoursetList] = useState([])
  const [placement, setPlacement] = useState([{ placements: [], 
    name:'',
    professionalCourse:'',
    college:'',
    course:'',
    company:'',
    designation:'',
    trainer:'',
    image:''}]);
  const { placements,name,professionalCourse,college,course,company,designation,trainer,image} = placement;

  useEffect(() => {
    (async()=>{
     const response= await getAllCourses()
     const data=response.data
     console.log(data,'coursescourses')
     setCoursetList(data)
    })();
   },[])
 
   let options=[];
   if (courseList?.length > 0 ) {
     options = courseList.map((value) => ({ label: value.title, value: value._id }));
   }

  const getCource=(data)=>{
    console.log(courseList.length,'sss')
      let res=courseList?.filter((el)=>el._id==data.course)
      return res?.title
   };
 
  useEffect(() => {
    getPlacement(id).then((data) => {
      console.log(data.course.title,'data.course.titledata.course.titledata.course.titledata.course.title')
      setPlacement({ ...placement, 
        name: data.name,
        professionalCourse:data.professionalCourse ,
        college:data.college,
        course: data.course?._id,
        company:data.company,
        designation:data.designation,
        trainer:data.trainer,
        image:data.image  });
    });

  }, []);
  
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setPlacement({ ...placement, [name]: e.target.value });
  };
  const handleChangeCource= (name) =>(e) =>{
    setPlacement({
      ...placement,
      [name]: e.value
    });
  };

  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setPlacement({
      ...placement,
      [name]: e.target.files[0]
    });
    setImgUrl(URL.createObjectURL(e.target.files[0]))
  };

  const placementUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
       name ,
       professionalCourse,
       college,
       course,
       company,
       designation,
       trainer
    };


    const formData = new FormData();
    if (image) {
      formData.append("file", image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
 

    axios({
      method: "put",
      // url: `http://localhost:9000/api/admin/placements/edit/${id}`,
      // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/placements/edit/${id}`,
      url: `https://api.luminartechnolab.com/api/admin/placements/edit/${id}`,

      
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setNotify({
          isOpen: true,
          message:'Updated Successfully',
          type:'success'
        })
        setTimeout(function(){
          navigate('/app/placement')
        }, 4000);      
          console.log(response);

      })
      .catch(function (response) {
        //handle error
        alert(response);
      });

    // updatePlacement(data,id).then((datas) => {
    //   if (datas.name === "Error") {
    //     alert(datas.message);
    //   } else {
    //       navigate('/placement')
    //   }
    // });
  };
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            name: '',
            professionalCourse: '',
            college: '',
            course: '',
            company: '',
            designation: '',
            trainer: '',
            image:''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('name is required'),
            professionalCourse: Yup.string().max(255).required('professionalCourse is required'),
            college: Yup.string().max(255).required('college is required'),
            course: Yup.string().max(255).required('course is required'),
            company: Yup.string().max(255).required('company is required'),
            designation: Yup.string().max(255).required('designation is required'),
            trainer: Yup.string().max(255).required('trainer is required'),
            image: Yup.string().max(255).required('image is required')
          })}
          onSubmit={() => {
            navigate('/app/placement', { replace: true });
          }}
        >
          {({ errors, handleBlur, handleSubmit, isSubmitting, touched }) => (
            <form onSubmit={placementUpdateHandler}>
                                <label>Full Name</label>

              <TextField        
              sx={{ maxWidth: 700 }}
              fullWidth
              name={'name'}
              value={name}
              onChange={handleChange('name')}
              placeholder="Full Name"
              variant="outlined"
              required
            />
            <br/><br/>
            <label>Graduated In</label>

            <TextField
              sx={{ maxWidth: 700 }}
              fullWidth
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <SvgIcon fontSize="small" color="action">
              //       </SvgIcon>
              //     </InputAdornment>
              //   )
              // }}
              name={'professionalCourse'}
              value={professionalCourse}
              onChange={handleChange('professionalCourse')}
              placeholder="Graduated In"
              variant="outlined"
              required
            /><br/><br/>
                        <label>College</label>
                        {console.log(college,"college")}
              <TextField
              sx={{ maxWidth:  700  }}
              fullWidth
              name={'college'}
              value={college}
              onChange={handleChange('college')}
              placeholder="College"
              variant="outlined"
              required
            /><br/><br/>
                <label>Course Done</label>
                {console.log(course,"coursecxxxxxxourse")}
                {
                  course&&
                  <Select
                   sx={{ maxWidth: 700 }}
                   fullWidth
                   value={course}
                   onChange={handleChange("course")}
                   name={'course'}
                   label={course}
                  // defaultValue={course}
                  // required
                   //variant="outlined"
                  // inputProps={{ 'aria-label': 'Without label' }}
                  >
                {
                   options.map((option)=> <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
                 }
                </Select>
                }
                  <br />
                  <br />
           <label>Placed In</label>
            <TextField
              sx={{ maxWidth:  700  }}
              fullWidth
              name={'company'}
              value={company}
              onChange={handleChange('company')}
              placeholder="Placed In"
              variant="outlined"
              required
            /><br/><br/>
               <label>Placed As</label>

            <TextField
              sx={{ maxWidth:  700  }}
              fullWidth
              name={'designation'}
              value={designation}
              onChange={handleChange('designation')}
              placeholder="Placed As"
              variant="outlined"
              required
            /><br/><br/>
                               <label>Trainer</label>

            <TextField
              sx={{ maxWidth:  700 }}
              fullWidth
              name={'trainer'}
              value={trainer}
              onChange={handleChange('trainer')}
              placeholder="Trainer"
              variant="outlined"
              required
            /><br/><br/>
               <label> Image</label>
               <Button>Choose File
                 <input type='file' 
                  onChange={handleChangeImg('image')}
                  key={image}
                  style={  {  opacity :0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top:'0',
                    left:'0',}}>
                  </input>
                  </Button>
                  <img src={imageUrl || image}  className={classes.Card1}></img>
                  <br/><br/>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  ml: -1
                }}
              ></Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Notification
        notify={notify}
        setNotify={setNotify}
        />
      </Container>
    </Box>
  );
};

export default PlacementEditor;
