import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import { useNavigate, Link,useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { getModules, deleteModule } from '../apiCalls/apiCalls';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
const ListModules = () => {
  const { id } = useParams();
  const [module, setModule] = useState([{ modules: [] }]);
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
 
  const { modules } = module;
  const navigate = useNavigate();
  useEffect(() => {
    getModules(id).then((data) => {
      setModule({
        ...module,
        modules: data,
      });
    });
  }, []);
  const allListHandler = () => {
    getModules(id)
      .then((data) => {
        if (data) {
          setModule({
            ...module,
            modules: data
          });
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (courseId,id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deleteModule(courseId,id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };

  return (
    <Card
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding:'5px'
      }}
    >
    

      <Button 
      color="primary"
      variant="contained"
      onClick={()=>{
        navigate(`/app/addModules/${id}`)
      }}
      >
        Add modules
      </Button>
      </Box>
      <PerfectScrollbar>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Module Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modules && modules.map((customer, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>{customer.moduleTitle}</TableCell>
                        <TableCell>{customer.moduleName}</TableCell>
                        <TableCell>{customer.description}</TableCell>
                        <TableCell>
                          <Link to="#">
                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"

                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removePageHandler(id,customer._id)
                                }
                              }) 
                            }}
                            />
                            </Link>
                             <Link to={`/app/editModule/${id}/${customer._id}`}>
                            {' '}
                            <div class="hover">
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                            </div>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};


export default ListModules;
