import { useState, useRef, useEffect } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
const axios = require('axios');
//import Select from "react-select";

import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  Grid,
  FormGroup,
  Divider,Select, MenuItem
} from '@material-ui/core';
import { createPlacement, getAllCourses } from '../apiCalls/apiCalls';
import Placement from 'src/pages/Placement';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  },
  card: {
    width: '1000px',
    height: '700'
  }
}));

 

const PlacementListToolbar = () => {
  const navigate = useNavigate();
  const classes = style();

  const [placement, setPlacement] = useState({
    fullName: '',
    graduatedIn: '',
    college: '',
    courseDone: '',
    placedIn: '',
    placedAs: '',
    trainer: '',
    profileImg: 'profileImg',
    placements: []
  });
  const [courseList,setCoursetList] = useState([])
  const {
    fullName,
    graduatedIn,
    college,
    courseDone,
    placedIn,
    placedAs,
    trainer,
    profileImg,
    placements
  } = placement;

  useEffect(() => {
   (async()=>{
    const response= await getAllCourses()
    const data=response.data
    console.log(data,'coursescourses')
    setCoursetList(data)
   })();
  },[])

  let options=[];
  if (courseList?.length > 0 ) {
    options = courseList.map((value) => ({ label: value.title, value: value._id }));
  }

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setPlacement({
      ...placement,
      [name]: e.target.value
    });
  };
  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setPlacement({
      ...placement,
      [name]: e.target.files[0]
    });
  };
 
  const placementSubmitHandler = (e) => {
    e.preventDefault();
        const data = {
      name: fullName,
      professionalCourse: graduatedIn,
      college: college,
      course: courseDone,
      company: placedIn,
      designation: placedAs,
      trainer: trainer,
    };
    const formData = new FormData();

    if (profileImg) {
      formData.append("file", profileImg);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));

    axios({
      method: "post",
      // url: "http://localhost:9000/api/admin/placements/create",
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/placements/create",
      url: "https://api.luminartechnolab.com/api/admin/placements/create",

      
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setPlacement({...placement,fullName: '',
        graduatedIn: '',
        college: '',
        courseDone: '',
        placedIn: '',
        placedAs: '',
        trainer:'',
        profileImg: null,
        placements: []})
        navigate(`/app/placement`)

      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });


  };
  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <Grid item xs={12}>
        <form  onSubmit={placementSubmitHandler}>

          {/* <FormGroup
            className={classes.formGroup}
            onSubmit={placementSubmitHandler}
          > */}
            <Box>
              <Card className={classes.card}>
                <CardContent>
                  <label>Full Name</label>
                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'fullName'}
                    value={fullName}
                    onChange={handleChange('fullName')}
                    placeholder="Full Name"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  <label>Graduated In</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <SvgIcon fontSize="small" color="action"></SvgIcon>
                    //     </InputAdornment>
                    //   )
                    // }}
                    name={'graduatedIn'}
                    value={graduatedIn}
                    onChange={handleChange('graduatedIn')}
                    placeholder="Graduated In"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  <label>College</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'college'}
                    value={college}
                    onChange={handleChange('college')}
                    placeholder="College"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  {/* <label>Course Done</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'courseDone'}
                    value={courseDone}
                    onChange={handleChange('courseDone')}
                    placeholder="Course Done"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br /> */}

                   <label>Course Done</label>
               
                  <Select
                   sx={{ maxWidth: 1000 }}
                   fullWidth
                   value={courseDone}
                   onChange={handleChange("courseDone")}
                   name={'courseDone'}
                   label={courseDone}
                   required
                   variant="outlined"
                   inputProps={{ 'aria-label': 'Without label' }}
                  >
                {
                   options.map((option)=> <MenuItem value={option.value}>{option.label}</MenuItem>)
                 }
                </Select>
               <br />
               <br />
                  
                  <label>Placed In</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'placedIn'}
                    value={placedIn}
                    onChange={handleChange('placedIn')}
                    placeholder="Placed In"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  <label>Placed As</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'placedAs'}
                    value={placedAs}
                    onChange={handleChange('placedAs')}
                    placeholder="Placed As"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  <label>Trainer</label>

                  <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={'trainer'}
                    value={trainer}
                    onChange={handleChange('trainer')}
                    placeholder="Trainer"
                    variant="outlined"
                    required
                  />
                  <br />
                  <br />
                  {/* <TextField
                    sx={{ maxWidth: 1000 }}
                    fullWidth
                    name={profileImg}
                    value={profileImg}
                    onChange={handleChange('profileImg')}
                    placeholder="Profile Image"
                    variant="outlined"
                    required
                  /> */}
                  <br/><br/>
                  <label>Image</label>

                  <input type='file' 
                  onChange={handleChangeImg('profileImg')}
                  name={'profileImg'}
                  key={'profileImg'}
                  >
                  </input>
                  <br /> <br /> <br />
                  <Button color="primary" variant="contained" type="submit">
                    Create
                  </Button>
                </CardContent>
              </Card>
            </Box>
            <br /> <br /> <br />
          {/* </FormGroup> */}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlacementListToolbar;

