import { useState,useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Box,
  Button,
  FormGroup,
    TextField,
  CardContent,
  Grid ,
  Link
} from '@material-ui/core';

import { addModule } from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(5)
    },
    card: {
      width: '1000px',
      height: 'auto'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 500
    },

    formGroup: {
      alignItems: 'center'
    }
  }));
const AddModules = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const classes = style();
    const [module, setmodule] = useState({
      title: '',
      moduleName:'',
      description:'',
      modules: []
    });
    const { title, moduleName, description, modules} = module;
    const navigateToCourse=()=>{
        navigate('/app/course');
      };
      const navigateToModuleList=()=>{
        navigate(`/app/listModules/${id}`);
      };
    const handleChange = (name) => (e) => {
      e.preventDefault();
      setmodule({
        ...module,
        [name]: e.target.value
      });
    };
    const courseSubmitHandler = (e) => {
      e.preventDefault();
      const data = {
        title: title,
        moduleName:moduleName,
        description:description,
        modules:modules,
      };
      addModule(data,id).then((datas) => {
        if (datas.name === 'Error') {
          alert(datas.message);
        } else {
          let newArray = [...modules, datas.data];
          setmodule({
            ...module,
            modules: newArray
          });
          setmodule({
              ...module,
            title:'',
            moduleName:'',
            description:''
          })

        }
      });

    };
    return (
      <form onSubmit={courseSubmitHandler}>
        <div className={classes.container}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormGroup className={classes.formGroup}>
                <Grid>
                  <Card className={classes.card}>
                    <CardContent>
                      <TextField
                        sx={{ maxWidth: 1000 }}
                        fullWidth
                        name={title}
                        value={title}
                        onChange={handleChange('title')}
                        placeholder="Title"
                        variant="outlined"
                        required
                      />
                      <br />
                      <br />
                      <TextField
                        sx={{ maxWidth: 1000 }}
                        fullWidth
                        name={moduleName}
                        value={moduleName}
                        onChange={handleChange('moduleName')}
                        placeholder="moduleName"
                        variant="outlined"
                        required
                      />
                      <br />
                      <br />
                      <TextField
                        sx={{ maxWidth: 1000 }}
                        fullWidth
                        name={description}
                        value={description}
                        onChange={handleChange('description')}
                        placeholder="description"
                        variant="outlined"
                        required
                      />
                      <br />
                      <br />
                    <Button 
                    color="primary" 
                    variant="contained" sx={{ mx: 1 }} onClick={navigateToCourse}>
                        Back
                      </Button>
                      <Button color="primary" 
                      variant="contained" type="submit">
                        Add Module
                      </Button>
                      <Button color="primary" 
                      variant="contained" sx={{ mx: 1 }} onClick={navigateToModuleList}>
                        View Modules
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <br /> <br /> <br />
              </FormGroup>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };
  
  export default AddModules;
  