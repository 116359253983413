import { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate, useParams } from 'react-router-dom';
import Notification from '../Notification';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios');

import {
  Card,
  Box,
  Button,
  FormGroup,
  TextField,
  CardContent,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getCourse, updateCourse } from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  card: {
    width: '1000px',
    height: 'auto'
  },
  Card1: {
    width: 50,
    margin: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const CourseEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();
  const [imageUrl, setImgUrl] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [detailsPageImg1Url, setDetailsPageImg1Url] = useState('');
  const [detailsPageImg2Url, setDetailsPageImg2Url] = useState('');
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [course, setCourse] = useState({
    title: '',
    modules: [],
    metaTitle: '',
    metaDescription:'',
    description: '',
    nextBatch: '',
    nextBatchCalicut:'',
    nextBatchTvm:'',
    duration: '',
    fee: '',
    onlineFee:'',
    RegistrationFee:'',
    certification: '',
    sliderImg: '',
    icon: '',
    url: '',
    bannerContent: '',
    bannerHeading:'',
    detailPageHeading: '',
    certificationSection: '',
    whyLearnCourse: '',
    howWeHelp: '',
    mode: '',
    detailsPageImg1: 'detailsPageImg1',
    detailsPageImg2: 'detailsPageImg2',
    extraContent: '',
    order:''
  });

  const {
    title,
    order,
    metaTitle,
    metaDescription,
    description,
    nextBatch,
    nextBatchCalicut,
    nextBatchTvm,
    duration,
    fee,
    onlineFee,
    RegistrationFee,
    certification,
    sliderImg,
    bannerContent,
    bannerHeading,
    detailPageHeading,
    certificationSection,
    url,
    whyLearnCourse,
    detailsPageImg1,
    detailsPageImg2,
    howWeHelp,
    extraContent,
    icon,
    mode
  } = course;
  const [randomKey, setRandomKey] = useState(Math.random());

  useEffect(() => {
    getCourse(id).then((data) => {
      setCourse({
        ...course,
        title: data.title,
        order:data.order,
        metaTitle: data.metaTitle,
        metaDescription:data.metaDescription,
        description: data.description,
        nextBatch: data.nextBatch,
        nextBatchCalicut:data.nextBatchCalicut,
        nextBatchTvm:data?.nextBatchTvm||'',
        duration: data.duration,
        fee: data.fee,
        onlineFee:data.onlineFee,
        RegistrationFee:data.RegistrationFee,
        certification: data.certification,
        sliderImg: data.sliderImg,
        icon: data.icon,
        url: data.url,
        bannerContent: data.bannerContent,
        bannerHeading:data.bannerHeading,
        detailPageHeading: data.detailPageHeading,
        certificationSection: data.certificationSection,
        whyLearnCourse: data.whyLearnCourse,
        howWeHelp: data.howWeHelp,
        mode: data.mode,
        detailsPageImg1: data.detailsPageImg1,
        detailsPageImg2: data.detailsPageImg2,
        extraContent: data.extraContent
      });
    });
  }, []);
  useEffect(()=>{
    setRandomKey(Math.random());
  },[course])
  const handleChangeImg = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeImg1 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
    setIconUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeImg3 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
    setDetailsPageImg2Url(URL.createObjectURL(e.target.files[0]));
  };
  const handleChangeImg2 = (name) => (e) => {
    e.preventDefault();
    setCourse({
      ...course,
      [name]: e.target.files[0]
    });
    setDetailsPageImg1Url(URL.createObjectURL(e.target.files[0]));
  };
  const handleChange = (name) => (e) => {
    if(!e.target){
      return
    }
    setCourse({ ...course, [name]: e.target.value });
  };
  const editorRef = useRef(null);
  const courseUpdateHandler = (e) => {
    e.preventDefault();
    if (tinymce.get('b1').getContent() == '') {
      toast.error("BannerContent is required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('b2').getContent() == '') {
      toast.error("Banner Header required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a2').getContent() == '') {
      toast.error("Certification section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a3').getContent() == '') {
      toast.error("WhyLearnCourse section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('a4').getContent() == '') {
      toast.error("HowWeHelp section required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    const data = {
      title,
      metaTitle,
      metaDescription,
      description,
      nextBatch,
      nextBatchCalicut,
      nextBatchTvm,
      mode,
      duration,
      fee,
      onlineFee,
      RegistrationFee,
      certification,
      url,
      order,
      extraContent: tinymce.get('a5').getContent(),
      bannerContent: tinymce.get('b1').getContent(),
      bannerHeading: tinymce.get('b2').getContent(),
      detailPageHeading: tinymce.get('a1').getContent(),
      certificationSection: tinymce.get('a2').getContent(),
      whyLearnCourse: tinymce.get('a3').getContent(),
      howWeHelp: tinymce.get('a4').getContent()
    };
    const formData = new FormData();
    if (sliderImg) {
      formData.append('file', sliderImg);
    }
    if (icon) {
      formData.append('icon', icon);
    }
    if (detailsPageImg1) {
      formData.append('detailsPageImg1', detailsPageImg1);
    }
    if (detailsPageImg2) {
      formData.append('detailsPageImg2', detailsPageImg2);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    axios({
      method: 'put',
      // url: `http://localhost:9000/api/admin/course/edit/${id}`,
      // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/course/edit/${id}`,
      url: `https://api.luminartechnolab.com/api/admin/course/edit/${id}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        setNotify({
          isOpen: true,
          message: 'Updated Successfully',
          type: 'success'
        });
        setTimeout(function () {
          navigate('/app/course');
        }, 3000);
      })
      .catch(function (response) {
        //handle error
        alert(response);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '35px'
        }}
      >
        <h2>Edit Course</h2>

        {/* <Button 
      color="primary"
      variant="contained"
      onClick={()=>{
        navigate(`/app/listModules/${id}`)
      }}
      >
        Manage modules
      </Button> */}
      </Box>
      <form onSubmit={courseUpdateHandler}>
        <Notification notify={notify} setNotify={setNotify} />
        <FormGroup className={classes.formGroup}>
          <Card className={classes.card}>
            <CardContent>
              <label>Title</label>
              <TextField
                fullWidth
                margin="normal"
                name={'title'}
                onChange={handleChange('title')}
                value={title}
                variant="outlined"
                placeholder="Title"
                required
              />
              <label>Meta Title</label>
              <TextField
                fullWidth
                margin="normal"
                name={'metaTitle'}
                onChange={handleChange('metaTitle')}
                value={metaTitle}
                variant="outlined"
                placeholder="Meta Title"
                required
              />
                <label>Meta Description</label>
              <TextField
                fullWidth
                margin="normal"
                name={'metaDescription'}
                onChange={handleChange('metaDescription')}
                value={metaDescription}
                variant="outlined"
                placeholder="Meta Description"
                required
              />
              
              <label>Description</label>
              <TextField
                fullWidth
                margin="normal"
                name={'description'}
                onChange={handleChange('description')}
                value={description}
                variant="outlined"
                placeholder="description"
                required
              />
              <label>Seo Url</label>
              <TextField
                fullWidth
                margin="normal"
                name={'url'}
                onChange={handleChange('url')}
                value={url}
                variant="outlined"
                placeholder="url"
                required
              />
              <label>Next Batch In Cochin</label>
              <TextField
                fullWidth
                margin="normal"
                name={'nextBatch'}
                onChange={handleChange('nextBatch')}
                value={nextBatch}
                placeholder="NextBatch"
                variant="outlined"
                required
              />
                <label>Next Batch In Calicut</label>

              <TextField
                fullWidth
                margin="normal"
                name={'nextBatchCalicut'}
                onChange={handleChange('nextBatchCalicut')}
                value={nextBatchCalicut}
                placeholder="NextBatch"
                variant="outlined"
                required
              />
              <label>Next Batch In Trivandrum</label>
              <TextField
                fullWidth
                margin="normal"
                name={'nextBatchTvm'}
                onChange={handleChange('nextBatchTvm')}
                value={nextBatchTvm}
                placeholder="NextBatch"
                variant="outlined"
                required
              />

              <label>Mode</label>
              <TextField
                fullWidth
                margin="normal"
                name={'mode'}
                onChange={handleChange('mode')}
                value={mode}
                placeholder="mode"
                variant="outlined"
                required
              />
              <label>Duration</label>
              <TextField
                fullWidth
                margin="normal"
                name={'duration'}
                onChange={handleChange('duration')}
                value={duration}
                placeholder="Duration"
                variant="outlined"
                required
              />
              <label>Fees (EMI Mode)</label>
              <TextField
                fullWidth
                margin="normal"
                name={'fee'}
                onChange={handleChange('fee')}
                value={fee}
                placeholder="fee"
                variant="outlined"
                required
              />
              <label>Fees (One time Payment mode)</label>
              <TextField
                fullWidth
                margin="normal"
                name={'onlineFee'}
                onChange={handleChange('onlineFee')}
                value={onlineFee}
                placeholder="onlineFee"
                variant="outlined"
                required
              />
              <label>RegistrationFee</label>
              <TextField
                fullWidth
                margin="normal"
                name={'RegistrationFee'}
                onChange={handleChange('RegistrationFee')}
                value={RegistrationFee}
                placeholder="RegistrationFee"
                variant="outlined"
                required
              />
                  <label>Order</label>
              <TextField
                fullWidth
                margin="normal"
                name={'order'}
                onChange={handleChange('order')}
                value={order}
                placeholder="order"
                variant="outlined"
                required
              />
              <label>Certification</label>
              <TextField
                fullWidth
                margin="normal"
                name={'certification'}
                onChange={handleChange('certification')}
                value={certification}
                placeholder="certification"
                variant="outlined"
                required
              />
              <label>Banner Image</label>
              <Button>
                Choose File
                <input
                  type="file"
                  onChange={handleChangeImg('sliderImg')}
                  key={sliderImg}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top: '0',
                    left: '0'
                  }}
                ></input>
              </Button>
              <img src={imageUrl || sliderImg} className={classes.Card1}></img>
              <br />
              <br />
              <label>Icon Image</label>
              <Button>
                Choose File
                <input
                  type="file"
                  onChange={handleChangeImg1('icon')}
                  key={icon}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top: '0',
                    left: '0'
                  }}
                ></input>
              </Button>
              <img src={iconUrl || icon} className={classes.Card1}></img>
              <br />
              <br />
              <label>Course details Image 1</label>
              <Button>
                Choose File
                <input
                  type="file"
                  onChange={handleChangeImg2('detailsPageImg1')}
                  key={detailsPageImg1}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top: '0',
                    left: '0'
                  }}
                ></input>
              </Button>
              <img
                src={detailsPageImg1Url || detailsPageImg1}
                className={classes.Card1}
              ></img>
              <br />
              <br />
              <label>Course details Image 2</label>
              <Button>
                Choose File
                <input
                  type="file"
                  onChange={handleChangeImg3('detailsPageImg2')}
                  key={detailsPageImg2}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    height: '50px',
                    borderRadius: '50%',
                    top: '0',
                    left: '0'
                  }}
                ></input>
              </Button>
              <img
                src={detailsPageImg2Url || detailsPageImg2}
                className={classes.Card1}
              ></img>
              <br />
              <br />
              <label>Banner Content</label>
              
              <Editor
                id="b1"
                initialValue={course.bannerContent}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter bannerContent here'
                }}
                name={'bannerContent'}
                key={`${randomKey}-bannerContent`}
                value={course.bannerContent}
                // onEditorChange={handleChange('bannerContent')}
              />
              <br/>
              <br/>
                            <label>Banner Heading</label>
              <Editor
                id="b2"
                initialValue={course.bannerHeading}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter bannerHeading here'
                }}
                name={'bannerHeading'}
                key={`${randomKey}-bannerHeading`}
                value={course.bannerHeading}
                // onEditorChange={handleChange('bannerHeading')}
              />
              <label>Detail Page Heading</label>
              <Editor
                id="a1"
                initialValue={'detailPageHeading'}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter CourseDetailPage Heading here'
                }}
                name={'detailPageHeading'}
                key={`${randomKey}-detailPageHeading`}
                value={detailPageHeading}
                // onEditorChange={handleChange('detailPageHeading')}
              />
              <label>Certification session</label>
              <Editor
                id="a2"
                initialValue={'certificationSection'}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter CourseDetailPage Heading here'
                }}
                name={'certificationSection'}
                key={`${randomKey}-certificationSection`}
                value={certificationSection}
                // onEditorChange={handleChange('certificationSection')}
              />{' '}
              <br /> <br />
              <label>Why Learn Course</label>
              <Editor
                id="a3"
                initialValue={'whyLearnCourse'}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter CourseDetailPage Heading here'
                }}
                name={'whyLearnCourse'}
                key={`${randomKey}-whyLearnCourse`}
                value={whyLearnCourse}
                // onEditorChange={handleChange('whyLearnCourse')}
              />
              <label>How we help</label>
              <Editor
                id="a4"
                initialValue={'howWeHelp'}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter CourseDetailPage Heading here'
                }}
                name={'howWeHelp'}
                key={`${randomKey}-howWeHelp`}
                value={howWeHelp}
                // onEditorChange={handleChange('howWeHelp')}
              />
              <Editor
                id="a5"
                // initialValue={extraContent}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  placeholder: 'Enter extraContent Heading here'
                }}
                name={'extraContent'}
                value={extraContent}
                key={`${randomKey}-extraContent`}
                // onEditorChange={handleChange('extraContent')}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </CardContent>
          </Card>
        </FormGroup>
      </form>
    </Box>
  );
};

export default CourseEditor;
