import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { getAllFaculties, deleteFaculty } from '../apiCalls/apiCalls';

const Faculty = () => {

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const [faculty, setFaculty] = useState([{ faculties: [], }]);
  const { faculties } = faculty;
  const navigate = useNavigate();

  useEffect(() => {
    getAllFaculties().then((data) => {
      setFaculty({
        ...faculty,
        faculties: data
      });
    });
  }, []);
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllFaculties(pageNumber)
    .then((data) => {
      if (data) {
        setFaculty({
          ...faculty,
          faculties: data
        });
      } else {
        alert(data.message);
      }
    })
    .catch((err) => {
      alert(err.message);
    });
  };
  const allListHandler = () => {
    getAllFaculties()
      .then((data) => {
        if (data) {
          setFaculty({
            ...faculty,
            faculties: data
          });
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (id) => {
    await deleteFaculty(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        allListHandler();
      }
    });
  };

  return (
    <Card       sx={{
      minHeight: '100%',
      py: 3
    }}>
      <PerfectScrollbar>
        <Box >
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  placeholder="name"
                  variant="outlined"
                  required
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CreatedAt</TableCell>
                <TableCell>facultyName</TableCell>
                <TableCell>subject</TableCell>
                <TableCell>linkedInUrl</TableCell>
                <TableCell>googlePlusUrl</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {faculties && faculties.filter((val)=>{
                  if(search==""){
                    return val
                  }else if(search){
                    if(val.facultyName){
                      if(val.facultyName.toLowerCase().includes(search.toLocaleLowerCase())){
                        return val
                      }
                    }
                  }
                }).map((customer,i) =>{
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {moment(customer.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>{customer.facultyName}</TableCell>
                        <TableCell>{customer.subject}</TableCell>
                        <TableCell>{customer.linkedInUrl}</TableCell>
                        <TableCell>{customer.googlePlusUrl}</TableCell>
                        <TableCell>{customer.image}</TableCell>
                        <TableCell>
                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => removePageHandler(customer._id)}
                          />
                          <Link to={`/app/editFaculty/${customer._id}`}>
                            {' '}
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>


      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination color="primary" count={2} size="small" page={page} onChange={handleChange} />
      </Box>
    </Card>
  );
};

// PageListResults.propTypes = {
//   customers: PropTypes.array.isRequired
// };

export default Faculty;
