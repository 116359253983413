import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  MenuList,
  Typography
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  BookOpen,
  PlayCircle,
  Type,
  Video,
  Menu,
  MessageSquare,
  Book,
  Monitor
} from 'react-feather';
import NavItem from './NavItem';
import Money from '@material-ui/icons/Money';
import PeopleOutlined from '@material-ui/icons/PeopleOutlined';

const user = {
  avatar:'https://luminartechnolabbucket.s3.ap-south-1.amazonaws.com/f76c5025-608b-4c44-985e-bd2cb64b641a-logo%20vanner%200.1.ad159943.png', //'/static/images/avatars/download.png' ,
  jobTitle: 'Admin',
  name: 'Luminartechnolab'
};

const items = [
  {
    href: '/app/dashboard',
    icon:  Menu,
    title: 'Dashboard'
  },
  {
    href: '/app/course',
    icon: Book,
    title: 'Courses'
  },
  {
    href: '/app/trainers',
    icon: ShoppingBagIcon,
    title: 'Trainers'
  },
  {
    href: '/app/placement',
    icon: PeopleOutlined,
    title: 'Placement'
  },
  {
    href: '/app/enquiry',
    icon: MessageSquare,
    title: 'Enquiry'
  },
  {
    href: '/app/contactForm',
    icon: MessageSquare,
    title: 'EnquiryForms'
  },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Gallery'
  // },
  {
    href: '/app/payment',
    icon: Money,
    title: 'Payment'
  },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Careers'
  // },
  // {
  //   href: '/',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  {
    href: '/app/gallery',
    icon: UserPlusIcon,
    title: 'Gallery'
  },
  // {
  //   href: '/app/faculty',
  //   icon: AlertCircleIcon,
  //   title: 'Faculty'
  // },
  {
    href: '/app/testimonials',
    icon: Type,
    title: 'Testimonials'
   
  },
  {
    href: '/app/home',
    icon: Video,
    title: 'HomeVideo'
  },
  {
    href: '/app/banner',
    icon: UserPlusIcon,
    title: 'Banner'
  },
  {
    href: '/app/joinNow',
    icon: PeopleOutlined,
    title: 'JoinNow'
  },
  {
    href: '/app/bookADemo',
    icon: PeopleOutlined,
    title: 'BookADemo'
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // overflow:'hidden'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
         
        }}
      >
        <Avatar
          //component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          //to="/app/account"
        />
        <Typography
          color= 'black'
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color='black'
          variant="h6"
          
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2  }}>
        <List >
          {items.map((item) => (
            <NavItem 
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1, }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 2,
          p: 2
        }}
      >
        {/* <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box> */}
      </Box>
    </Box>
  );

  return (
    <>
      {/* <Hidden lgUp> */}
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      {/* </Hidden> */}
      {/* <Hidden xlDown> */}
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              color:'#6d1d8e'
             
            }
          }}
        >
          {content}
        </Drawer>
      {/* </Hidden> */}
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
