import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
const axios = require('axios');
import Notification from '../Notification';
import { Formik } from 'formik';

import {
  Card,
  Box,
  Button,
  FormGroup,
  TextField,
  CardContent,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  getTrainer,
  getTestimonial,
  updateTestimonial
} from '../apiCalls/apiCalls';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  Card1: {
    width: '50px',
    margin: 'auto',
    height: '50px'
  },
  card: {
    width: '1000px',
    height: 'auto'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const TrainerEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();

  const [trainer, setTrainer] = useState({
    name: '',
    subject: '',
    linkedInLink: '',
    facebookLink: '',
    twitterLink: '',
    instagramLink:'',
    image: '',
    youtubeLink:'',
    description:''
  });
  const { name, subject, linkedInLink,instagramLink, facebookLink, twitterLink,description,youtubeLink, image } =
    trainer;
  const [status, setStatus] = useState(false);
  const [imageUrl, setImgUrl] = useState('');
 const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  useEffect(() => {
    getTrainer(id).then((data) => {
      setTrainer({
        ...trainer,
        name: data.name,
        subject: data.subject,
        linkedInLink: data.linkedInLink,
        facebookLink: data.facebookLink,
        twitterLink: data.twitterLink,
        youtubeLink:data.youtubeLink,
        instagramLink:data.instagramLink,
        description:data.description,
        image: data.image
      });
    }, );
    },[]);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setTrainer({ ...trainer, [name]: e.target.value });
  };

  const handleChangeImg = (name) => (e) => {
    e.preventDefault();
    setTrainer({
      ...trainer,
      [name]: e.target.files[0]
    });
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  };
  const pageUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
      name,
      subject,
      facebookLink,
      twitterLink,
      youtubeLink,
      linkedInLink,
      instagramLink,
      description
    };
    const formData = new FormData();
    if (image) {
      formData.append('file', image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));

    axios({
      method: 'put',
      // url: `http://localhost:9000/api/admin/trainers/edit/${id}`,
      // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/trainers/edit/${id}`,
      url: `https://api.luminartechnolab.com/api/admin/trainers/edit/${id}`,

      
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
           setNotify({
             isOpen: true,
             message:'Updated Successfully',
             type:'success'
           })
           setTimeout(function(){
        navigate('/app/trainers')
  }, 3000);
        //handle success
      })
      .catch(function (response) {
        //handle error
        alert(response);
      });

    // updateTestimonial(data,id).then((datas) => {
    //   if (datas.name === "Error") {
    //     alert(datas.message);
    //   } else {
    //       navigate('/app/testimonials')
    //   }
    // });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <form onSubmit={pageUpdateHandler}>
      <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <FormGroup className={classes.formGroup}>
          <Grid>
            <Card className={classes.card}>
              <CardContent>
                <label>Name</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'name'}
                  onChange={handleChange('name')}
                  value={name}
                  variant="outlined"
                  placeholder="name"
                  required
                />
                <br />
                <br />
                <label>Subject</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'subject'}
                  onChange={handleChange('subject')}
                  value={subject}
                  variant="outlined"
                  placeholder="subject"
                  required
                />
                <br />
                <br />
                <label>LinkedIn Link</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'linkedInLink'}
                  onChange={handleChange('linkedInLink')}
                  value={linkedInLink}
                  variant="outlined"
                  placeholder="linkedInLink"
                  required
                />
                <br />
                <br />
                <label>Facebook link</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'facebookLink'}
                  onChange={handleChange('facebookLink')}
                  value={facebookLink}
                  variant="outlined"
                  placeholder="facebookLink"
                  required
                />
                <br />
                <br />
                <label>Youtube Link</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'twitterLink'}
                  onChange={handleChange('youtubeLink')}
                  value={youtubeLink}
                  variant="outlined"
                  placeholder="youtube Link"
                  required
                />
                <br />
                <br /> <br />
                <label>Instagram Link</label>
                <TextField
                  fullWidth
                  margin="normal"
                  name={'instagramLink'}
                  onChange={handleChange('instagramLink')}
                  value={instagramLink}
                  variant="outlined"
                  placeholder="instagramLink"
                  required
                />
                <br />
                <br />
                <label>Description  (Maximum 500 Characters)</label>

<TextField
  fullWidth
  name={'description'}
  value={description}
  onChange={handleChange('description')}
  placeholder="description"
  variant="outlined"
  multiline
  inputProps={{ maxLength: 500 }}
  maxRows={3}
  rows={3}
 /><br/><br/><br></br>
                <Button>
                  Choose File
                  <input
                    type="file"
                    onChange={handleChangeImg('image')}
                    name={'image'}
                    key={image}
                    style={{
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      zIndex: 1,
                      width: 'auto',
                      height: '50px',
                      borderRadius: '50%',
                      top: '0',
                      left: '0'
                    }}
                  ></input>
                </Button>
                <img src={imageUrl || image} className={classes.Card1}></img>
                <br /> <br /> <br />
                <Button color="primary" variant="contained" type="submit">
                  Update
                </Button>

              </CardContent>
            </Card>
          </Grid>
        </FormGroup>

      </form>
    </Box>
  );
};

export default TrainerEditor;
