const API = 'http://admin.luminartechnolab.com/api';
//const API = 'https://api.luminartechnolab.com/api/admin';

//const API = 'http://localhost:3004/api';

const axios = require('axios');
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Redirect to the login page here
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};

//pages

 export const getAllPages = (data) => { 
     return axios.get(`${API}/page/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data.data;
    })
    .catch((err) => {
      return err
    });
};
export const createPage = (data) =>{
  return axios.post(`${API}/page/create`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}
export const deletePage = (id) =>{
  return axios.delete(`${API}/page/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
     // return err.response.data
      return err

  })
}
export const getPage = (id) =>{
  return axios.get(`${API}/page/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}
export const updatePage = (data,id) =>{

  return axios.put(`${API}/page/edit/${id}`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}
export const filterPage= (status,data) => { 
    return axios.get(`${API}/page/filterList/${status}?page=${data}&limit=${10}`)
   .then((data) => {
     return data.data.data;
   })
   .catch((err) => {
     return err
   });
}
//placement

export const createPlacement = (data) =>{
  return axios.post(`${API}/placements/create`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}



  export const createBanner= (data) =>{
    return axios.post(`${API}/HomeBanner/create`,
        data,{headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        return err.response.data
    })
  }
export const getAllPlacements = (data) => { 
    return axios.get(`${API}/placements/list?page=${data}&pageSize=${10}`)
   .then((data) => {
     return data.data;
   })
   .catch((err) => {
     return err
   });
};

export const deletePlacement = (id) =>{
  return axios.delete(`${API}/placements/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const deletePayment = (id) =>{
  return axios.delete(`${API}/payment/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const getBanner = (id) =>{
  return axios.get(`${API}/HomeBanner/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}
export const updateBanner = (data,id) =>{
  return axios.put(`${API}/HomeBanner/edit/${id}`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const getPlacement = (id) =>{
  return axios.get(`${API}/placements/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const updatePlacement = (data,id) =>{
  return axios.put(`${API}/placements/edit/${id}`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const searchPlacements = (name) =>{
  return axios.get(`${API}/placements/searchPlacements`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}


export const  getPDF=()=> {
  return axios.get(`${API}/placements/pdf`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf'
    }
  })
}




//gallery

export const getAllGallery = (data) => {
  return axios.get(`${API}/gallery/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};
export const CreateGallery = (data) => {
  return axios.post(`${API}/gallery/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const deleteGallery = (id) => {
  return axios.delete(`${API}/gallery/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

export const getGallery = (id) => {
  return axios.get(`${API}/gallery/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


//testimonials

export const createTestimonial = (data) => {
  return axios.post(`${API}/testimonial/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

export const getAllTestimonials = (data) => {
  return axios.get(`${API}/testimonial/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};

export const deleteTestimonial = (id) => {
  return axios.delete(`${API}/testimonial/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const deleteTrainers = (id) => {
  return axios.delete(`${API}/trainers/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}



export const getTestimonial = (id) => {
  return axios.get(`${API}/testimonial/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const getTrainer = (id) => {
  return axios.get(`${API}/trainers/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const updateTestimonial = (data, id) => {


  return axios.put(`${API}/testimonial/edit/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

export const searchTestimonial = (name) => {
  return axios.get(`${API}/testimonial/searchTestimonial`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

//faculty


export const createFaculty = (data) => {
  return axios.post(`${API}/faculty/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const getFaculty = (id) => {
  return axios.get(`${API}/faculty/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}
export const updateFaculty = (data, id) => {

  return axios.put(`${API}/faculty/edit/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

export const searchFaculty = (name) => {
  return axios.get(`${API}/faculty/searchFaculty`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const getAllFaculties = (data) => {
  return axios.get(`${API}/faculty/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data.data;
    })
    .catch((err) => {
      return err
    });
};

export const deleteFaculty = (id) => {
  return axios.delete(`${API}/faculty/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


//enquiry

export const createEnquiry = (data) => {
  return axios.post(`${API}/enquiry/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const getEnquiry = (id) => {
  return axios.get(`${API}/enquiry/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}
export const updateEnquiry = (data, id) => {


  return axios.put(`${API}/enquiry/edit/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

export const searchEnquiry = (name) => {
  return axios.get(`${API}/enquiry/searchEnquiry`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const getAllEnquiry = (data) => {
  return axios.get(`${API}/enquiry/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};

export const getAllEnquiryForms = (data) => {
  return axios.get(`${API}/EnquiryForm/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};

export const getAllJoinNow = (data) => {
  return axios.get(`${API}/joinNow/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};


export const getAllBookADemo = (data) => {
  return axios.get(`${API}/bookADemo/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};



export const deleteEnquiry = (id) => {
  return axios.delete(`${API}/enquiry/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}
export const deleteEnquiryForms = (id) => {
  return axios.delete(`${API}/EnquiryForm/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const deleteJoinNow = (id) => {
  return axios.delete(`${API}/joinNow/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}



export const deleteBookADemo = (id) => {
  return axios.delete(`${API}/bookADemo/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

//course

export const createCourse = (data) => {
  return axios.post(`${API}/course/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const getAllCourses = (data) => {
  return axios.get(`${API}/course/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};
export const getAllBanners = (data) => {
  return axios.get(`${API}/HomeBanner/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};
export const deleteBanner = (id) => {
  return axios.delete(`${API}/HomeBanner/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const deleteCourse = (id) => {
  return axios.delete(`${API}/course/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

export const getCourse = (id) => {
  return axios.get(`${API}/course/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}
export const updateCourse = (data, id) => {
  return axios.put(`${API}/course/edit/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

export const searchCourse = (name) => {
  return axios.get(`${API}/course/searchCourse`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}




export const addModule = (data, courseId) => {
  return axios.put(`${API}/course/addModule/${courseId}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const getModules = (id) => {
  return axios.get(`${API}/course/modules/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}


export const getModuleById = (courseId, id) => {
  return axios.get(`${API}/course/modules/getModuleById/${courseId}/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

export const deleteModule = (courseId, id) => {
  return axios.delete(`${API}/course/modules/${courseId}/${id}`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}

export const updateModule = (data, id, courseId) => {
  return axios.put(`${API}/course/modules/edit/${courseId}/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

//login

export const AdminLogin = (data) => {
  return axios.post(`${API}/admin/login`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


//home

export const createHome = (data) => {
  return axios.post(`${API}/home/create`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}


export const getHome = (name) => {
  return axios.get(`${API}/home/getHome`).then((data) => {
    return data.data
  }).catch((err) => {
    return err.response.data
  })
}



export const updateHome = (data, id) => {
  return axios.put(`${API}/home/edit/${id}`,
    data, { headers }).then((data) => {
      return data.data
    }).catch((err) => {
      return err.response.data
    })
}

//payments

export const getAllPayments = (data) => {
  return axios.get(`${API}/payment/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};
export const getAllPaymentforCsv = (data) => {
  return axios.get(`${API}/payment/listforCsv`)
    .then((data) => {
      return data.data.data;
    })
    .catch((err) => {
      return err
    });
};

export const getAllTrainers = (data) => {
  return axios.get(`${API}/trainers/list?page=${data}&limit=${10}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err
    });
};