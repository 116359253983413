import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import {AdminLogin} from '../components/apiCalls/apiCalls'
import Dashboard from "./Dashboard";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import '../pages/login.css'
const Login = () => {
  const navigate = useNavigate();
  const [login,setLogin]=useState([{logins:[],email:"",password:""}])
  const {email,logins,password}= login;
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setLogin({ ...login, [name]: e.target.value });
  };


  const navigateHandler = (e) => {
   navigate('/app/dashboard')
  };


  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""

    },
    validationSchema: Yup.object({

      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().max(255).required('Password is required')

    }),
    onSubmit: (values, { resetForm }) => {
      AdminLogin(values).then((datas) => {
        if (datas.message === "Login successfully") {
          // Store the token in sessionStorage
          localStorage.setItem('token', datas.token);
          toast.success("Login Success", {
            position: "top-center",
            autoClose: 2000,
          },
          );
          setTimeout(function () { navigateHandler(); }, 3000);


        } else {
          toast.error("login failed", {
            position: "top-center",
            autoClose: 5000,
          },)
          resetForm();
        }
      });
    },
    handleSubmit: (event) => {
      event.preventDefault();
    },
  });
  return (
    <>
      <Helmet>
        <title>Luminar Technolab</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Login
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Login in as admin
                  </Typography>
                </Box>

                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                     login with email address
                  </Typography>
                </Box>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                        contact-us-input-field"
                    id="email"
                    placeholder="  Type your email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </div>
                <br/>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control
                                contact-us-input-field"
                    id="password"
                    placeholder="  Type your Password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </div>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>

              </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
