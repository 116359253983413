import { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios');

import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  Grid,
  FormGroup,
  Divider
} from '@material-ui/core';
import { createBanner } from '../apiCalls/apiCalls';
import Placement from 'src/pages/Placement';
const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500
  },
  formGroup: {
    alignItems: 'center'
  },
  card: {
    width: '1000px',
    height: '700'
  }
}));
const CreateBanner = () => {
  const navigate = useNavigate();
  const classes = style();

  const [banner, setBanner] = useState({
    bannerContent: '',
    bannerContentBottom:'',
    bannerImg: 'bannerImg',
    banners: []
  });
  const {
    bannerContent,
    bannerContentBottom,
    bannerImg,
    banners
  } = banner;
  const editorRef = useRef(null);

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setBanner({
      ...banner,
      [name]: e.target.value
    });
  };
  const handleChangeImg= (name) => (e) => {
    e.preventDefault();
    setBanner({
      ...banner,
      [name]: e.target.files[0]
    });
  };
  const bannerSubmitHandler = (e) => {

    e.preventDefault();

    if (tinymce.get('a1').getContent() == '') {
      toast.error("Banner Heading required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
    if (tinymce.get('b1').getContent() == '') {
      toast.error("Banner Content required", {
        position: "top-center",
        autoClose: 2000,
    },)     
    return
    }
        const data = {
      bannerContent: tinymce.get("a1").getContent(),
      bannerContentBottom:tinymce.get("b1").getContent(),
    };
    const formData = new FormData();

    if (bannerImg) {
      formData.append("file", bannerImg);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));

    axios({
      method: "post",
      // url: "http://localhost:9000/api/admin/HomeBanner/create",
      // url: "https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/HomeBanner/create",
      url: "https://api.luminartechnolab.com/api/admin/HomeBanner/create",

     
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        setBanner({...banner,
        bannerContent:'',
        bannerContentBottom:'',
        bannerImg: null,
        banners: []})
        navigate('/app/banner')
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });


  };
  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <Grid item xs={12}>
        <form  onSubmit={bannerSubmitHandler}>

          {/* <FormGroup
            className={classes.formGroup}
            onSubmit={placementSubmitHandler}
          > */}
            <Box>
              <Card className={classes.card}>
                <CardContent>

<label>Banner content Deading</label>
                <Editor id="a1"
                      onInit={(evt, editor) => (editorRef.current = editor)} 
                      initialValue={bannerContent}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'preview',

                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'preview'+

                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          placeholder:'Enter bannerContent  here',

                      }}
                      name={"bannerContent"}
                      value={bannerContent}
                      onChange={handleChange('bannerContent')}
                    />
                  <br />
                  <br />
                  <label>Banner content Paragraph</label>

                  <Editor id="b1"
                      onInit={(evt, editor) => (editorRef.current = editor)} 
                      initialValue={bannerContentBottom}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'preview',

                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'preview'+

                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          placeholder:'Enter bannerContentBottom  here',

                      }}
                      name={"bannerContentBottom"}
                      value={bannerContentBottom}
                      onChange={handleChange('bannerContentBottom')}
                    />
                  <br />
                  <br />
                  <input type='file' 
                  onChange={handleChangeImg('bannerImg')}
                  name={'bannerImg'}
                  key={'bannerImg'}
                  >
                  </input>
                  <br /> <br /> <br />
                  <Button color="primary" variant="contained" type="submit">
                    Create
                  </Button>
                </CardContent>
              </Card>
            </Box>
            <br /> <br /> <br />
          {/* </FormGroup> */}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateBanner;

