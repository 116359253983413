import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import Trainers from '../pages/trainers';
import { makeStyles } from '@material-ui/styles';
const style = makeStyles((theme) => ({
   root: {
     top: theme.spacing(9)
   }
 }));
function Notification(props) {
    const {notify,setNotify} = props;
    const classes = style();

    const handleClose=(event,reason)=>{

      setNotify({...notify,
         isOpen:false
      })
    }
  return(
     <>
     {notify&& notify.type?  <div>{console.log(notify.type)}   <Snackbar
     open={notify.isOpen}
     autoHideDuration={3000}
     className= {classes.root}
     onClose={handleClose}
     anchorOrigin={{vertical:'top',horizontal:'right'}}
     >
     <Alert severity={notify.type}>
        {notify.message}
     </Alert>
     </Snackbar>
     </div> :null}
     </>
  )
}

export default Notification;
