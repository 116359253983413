import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import AddIcon from '@material-ui/icons/Add';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TablePagination,
  TableRow,
  Chip,
  CardContent,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { getAllCourses, deleteCourse } from '../apiCalls/apiCalls';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
const Courses = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})

  const [limit, setLimit] = useState(0);
  const [course, setCourse] = useState([{ courses: [] }]);
  const { courses } = course;
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  useEffect(() => {
    getAllCourses().then((data) => {
      setCourse({
        ...course,
        courses: data.data
      });
      setLimit(data.TotalNoPages)
    });
  }, []);
  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllCourses(pageNumber)
      .then((data) => {
        if (data) {
          setCourse({
            ...course,
            courses: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log("err",err)
        alert(err.message);
      });
  };
  const allListHandler = () => {
    getAllCourses()
      .then((data) => {
        if (data) {
          setCourse({
            ...course,
            courses: data.data
          });
          setLimit(data.TotalNoPages)

        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const removePageHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
    await deleteCourse(id).then((datas) => {
      if (datas.name === 'Error') {
        alert(datas.message);
      } else {
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler();
      }
    });
  };
  return (
    <Card
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
  <PerfectScrollbar>
  <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
        <Box>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  placeholder="name"
                  variant="outlined"
                  required
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>NextBatch</TableCell>
                <TableCell>Mode</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses &&
                courses
                  .filter((val) => {
                    if (search == '') {
                      return val;
                    } else if (search) {
                      if (val.title) {
                        if (
                          val.title
                            .toLowerCase()
                            .includes(search.toLocaleLowerCase())
                        ) {
                          return val;
                        }
                      }
                    }
                  })
                  .map((customer, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>{customer.title}</TableCell>
                        <TableCell>{customer.nextBatch}</TableCell>
                        <TableCell>{customer.mode}</TableCell>
                        <TableCell>{customer.duration}</TableCell>
                        <TableCell>{customer.fee}</TableCell>
                        <TableCell>
                        <div class="hover">
                          <Link to="#">
                          <DeleteTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => {
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen:true,
                                title:'Are you sure to delete this record?',
                                subTitle:"You can't undo this operation",
                                onConfirm:()=>{
                                  removePageHandler(customer._id)
                                }
                              }) 
                            }}
                          />
                          </Link>
                          <Link to={`/app/editCourse/${customer._id}`}>
                            {' '}
                            <EditIcon
                              color="primary"
                              fontSize="small"
                            ></EditIcon>
                          </Link>
                          <Link to={`/app/listModules/${customer._id}`}>

                         <AddIcon
                           color="primary"
                           fontSize="small"
                            />
                         </Link>
                         </div>


                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination
          color="primary"
          count={limit}
          size="small"
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Card>
  );
};

// PageListResults.propTypes = {
//   customers: PropTypes.array.isRequired
// };

export default Courses;
