import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import {useNavigate,Link} from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';

const TrainerList = () =>{

  return (
  <Box >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      {/* <Button>
        Import
      </Button>
      <Button sx={{ mx: 1 }}>
        Export
      </Button> */}
      <Link to={`/app/createTrainer/`}>   
      <Button
        color="primary"
        variant="contained"
      >
       Create   
   </Button>
      </Link> 
    </Box>
  </Box>
);
 }

export default TrainerList;
