import { useState, useEffect } from 'react';
import moment from 'moment';
const axios = require('axios');
import Notification from '../Notification';

import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Card,
  Box,
  Button,
  FormGroup,
    TextField,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getTestimonial, updateTestimonial } from '../apiCalls/apiCalls';
const style = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),

  },
  Card1: {
    width: '50px',
    margin: 'auto',
    height: '50px'
  },
  card: {
    width: '1000px' ,
    height: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  formGroup: {
    alignItems: 'center'
  }
}));
const TestimonialEditor = () => {
  const classes = style();

  const { id } = useParams();
  const navigate = useNavigate();

  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})

  const [testimonial, setTestimonial] = useState({
    fullName:'',
    course:'',
    designation:'',
    company:'',
    testimony:'',
    image:'image',

  });
  const {fullName,course,designation,company,image,testimony} = testimonial
  const [imageUrl, setImgUrl] = useState('');
  useEffect(() => {
    getTestimonial(id).then((data) => {
      setTestimonial({ ...testimonial, 
        fullName: data.fullName ,
        course: data.course,
        designation:data.designation,
        company:data.company,
        testimony:data.testimony,
        image:data.image
      });

    });
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setTestimonial({ ...testimonial, [name]: e.target.value });
  };


  const handleChangeImg = (name) => (e) => {
    e.preventDefault();
    setTestimonial({
      ...testimonial,
      [name]: e.target.files[0]
    });
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  };


  const pageUpdateHandler = (e) => {
    e.preventDefault();
    const data = {
      fullName,
      course,
      designation,
      company,
      testimony
    };

    const formData = new FormData();
    if (image) {
      formData.append('file', image);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));


    axios({
      method: 'put',
      // url: `http://localhost:9000/api/admin/testimonial/edit/${id}`,
      // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/admin/testimonial/edit/${id}`,
      url: `https://api.luminartechnolab.com/api/admin/testimonial/edit/${id}`,

      
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        setNotify({
          isOpen: true,
          message:'Updated Successfully',
          type:'success'
        })
        setTimeout(function(){
          navigate('/app/testimonials');
        }, 5000);
        //handle success
      })
      .catch(function (response) {
        //handle error
        alert(response);
      });
    



    // updateTestimonial(data,id).then((datas) => {
    //   if (datas.name === "Error") {
    //     alert(datas.message);
    //   } else {
    //       navigate('/app/testimonials')
    //   }
    // });
  };
  return (


    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start'
      }}
    >
        <form onSubmit={pageUpdateHandler} >
        <Notification
        notify={notify}
        setNotify={setNotify}
        />
            <FormGroup className={classes.formGroup}  >
              <Card  className={classes.card}>
                <CardContent >
                  <label>Full Name</label>
              <TextField 
                fullWidth
                margin="normal"
                name={'fullName'}
                onChange={handleChange('fullName')}
                value={fullName}
                variant="outlined"
                required
              />
   <label>Course</label>

              <TextField 
                fullWidth
                margin="normal"
                name={'course'}
                onChange={handleChange('course')}
                value={course}
                variant="outlined"
                required
              />

<label>Designation</label>

<TextField 
  fullWidth
  margin="normal"
  name={'designation'}
  onChange={handleChange('designation')}
  value={designation}
  variant="outlined"
  required
/>
                 <label>Company</label>

              <TextField
                fullWidth
                margin="normal"
                name={'company'}
                onChange={handleChange('company')}
                value={company}
                variant="outlined"
                required
              />

<label>Testimonial</label>

<TextField 
  fullWidth
  margin="normal"
  name={'testimony'}
  onChange={handleChange('testimony')}
  value={testimony}
  variant="outlined"
  required
/>



          <label>Image</label>

                <Button>
                  Choose File
                  <input
                    type="file"
                    onChange={handleChangeImg('image')}
                    name={'image'}
                    key={image}
                    style={{
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      zIndex: 1,
                      width: 'auto',
                      height: '50px',
                      borderRadius: '50%',
                      top: '0',
                      left: '0'
                    }}
                  ></input>
                </Button>
                <img src={imageUrl || image} className={classes.Card1}></img>
                <br /> <br /> <br />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
              </CardContent> 
            </Card>
            </FormGroup>
        </form>
    </Box>

  );
};

export default TestimonialEditor;
