import { useState, useEffect } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useNavigate} from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  InputAdornment,
  SvgIcon,
  FormControlLabel,
  Grid,
  Checkbox
} from '@material-ui/core';
import { createPage, getAllPages } from '../apiCalls/apiCalls';
const PageListToolbar = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(
    {
      pageName:"",
      pages: [],
    }
  );
  const { pageName ,pages } = page;

  useEffect(() => {
    getAllPages().then((data) => {
      setPage({
        ...page, pages:data
      })})       
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setPage({
      ...page,
      [name]: e.target.value,
    });
  };
  const pageSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      title: pageName,
    };
    createPage(data).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      } else {
        let newArray= [...pages,datas.data]
        setPage({
          ...page, pages:newArray
          })
          navigate('/pages')
      }
    });
  };
  return(
  <form  onSubmit={pageSubmitHandler}>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <TextField
              sx={{ maxWidth: 500 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              name={pageName}
              value={pageName}
              onChange={handleChange('pageName')}
              placeholder="Add page here"
              variant="outlined"
              required
            />
            <Button color="primary" variant="contained" type="submit" >
              Create
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
    <br /> <br /> <br />
  </form>
  )
}

export default PageListToolbar;
