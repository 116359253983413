import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PageListResults from '../components/page/PageListResults';
import PageListToolbar from '../components/page/PageListToolbar';
const Page = () => (
  <>
    <Helmet>
      <title>Luminar Technolab</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <PageListToolbar />
        <Box sx={{ minWidth: 1050 }}>
          <PageListResults  />
        </Box>
      </Container>
    </Box>
  </>
);

export default Page;
