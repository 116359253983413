import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import {useNavigate,Link} from 'react-router-dom';
import Notification from '../Notification';
import ConfirmDialogue from '../ConfirmDialogue';
import Pagination from '@mui/material/Pagination';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  Button,
  Grid,
  CardContent,
  TextField,
  InputAdornment ,
  SvgIcon 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getAllPlacements,deletePlacement } from '../apiCalls/apiCalls';
import PlacementEditor from './PlacementEditor';
import PlacementSearch from './PlacementSearch';
import { Search as SearchIcon } from 'react-feather';
const style = makeStyles({
  titleItemRight: {
    color: "white",
    backgroundColor: "blue",
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
  titleBar :{
   padding : "30px"
  },
  Card: {
    width: 50,
    margin: 'auto'
  },
  Media: {
    height: '100%',
    width: '100%'
  }
});
const PlacemtListResults = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);

  const [placement, setPlacement] = useState([{ placements: [], }]);
  const [search, setSearch] = useState('');
  const[notify,setNotify]=useState({isOpen:false,message:'',type:''})
  const [confirmDialog,setConfirmDialog]=useState({isOpen:false,title:'',subTitle:''})
  const { placements } = placement;
  const navigate = useNavigate();
  const classes = style();

  useEffect(() => {
    getAllPlacements(page).then((data) => {
      setPlacement({
        ...placement, placements:data.data
      })
      setLimit(data.TotalNoPages)
    })     
  }, []);

  const allListHandler = ()  => {
    getAllPlacements()
      .then((data) => {
        if(data){
          setPlacement({
            ...placement, placements:data.data
          }) 
          setLimit(data.TotalNoPages)  
           
        }
         else {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleChange = (event, pageNumber) => {
    setPage(pageNumber);
    getAllPlacements(pageNumber)
    .then((data) => {
      if (data) {
        setPlacement({
          ...placement, placements:data.data
        })
        setLimit(data.TotalNoPages)  
            
      }
       else {
        alert(data.message);
      }
    })
    .catch((err) => {
      alert(err.message);
    });
  };

  const removePlacementHandler = async (id) => {
    setConfirmDialog({...confirmDialog,
      isOpen:false})
   await deletePlacement(id).then((datas) => {
      if (datas.name === "Error") {
        alert(datas.message);
      }
      else{
        setNotify({
          isOpen: true,
          message:'Deleted Successfully',
          type:'error'
        })
        allListHandler()
      }
    });
  };

  return (
    <Card >

     <PerfectScrollbar>
     <Notification
        notify={notify}
        setNotify={setNotify}
        />
        <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        />
     <Box >
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <TextField
              sx={{ maxWidth: 500 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              onChange={(e)=>{
                setSearch(e.target.value)
              }}
              value={search}
              placeholder="search here"
              variant="outlined"
              required
            />
            <Button color="primary" variant="contained" type="submit" >
              <SearchIcon/>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
        <Box sx={{ minWidth: 'auto' }}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                {/* <TableCell>Created By</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Graduate In</TableCell>
                <TableCell>College</TableCell>
                <TableCell>Course Done</TableCell>
                <TableCell>Placed In</TableCell>
                <TableCell>Placed As</TableCell>
                <TableCell>Trainer</TableCell>
                <TableCell>Profile Img</TableCell>
                <TableCell>Created At</TableCell>

                <TableCell>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
            {placements  &&(
               placements.filter((val)=>{
                  if(search==""){
                    return val
                  }else if(search){
                    if(val.name){
                      if(val.name.toLowerCase().includes(search.toLocaleLowerCase())){
                        return val
                      }
                    }
                  }
                }).map((customer,i)=>{  
              return(

                <TableRow
                  hover
                   key={i}
                >
                  <TableCell>{i+1} </TableCell>
                  {/* <TableCell>{customer.name} </TableCell> */}
                  <TableCell>{customer.name} </TableCell>
                  <TableCell>{customer.professionalCourse} </TableCell>

                  <TableCell>{customer.college} </TableCell>
                  <TableCell>{customer.course?.title} </TableCell>
                  <TableCell>{customer.company} </TableCell>
                  <TableCell>{customer.designation} </TableCell>
                  <TableCell>{customer.trainer} </TableCell>
                  <TableCell>
                    <img src={`${customer.image}`} className={classes.Card}></img>
                  </TableCell>
                  <TableCell>
                  {moment(customer.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                  <TableCell>
                  <Link to="#">

                    <DeleteTwoToneIcon color="primary" fontSize="medium" onClick={() =>{
                                                    setConfirmDialog({
                                                      ...confirmDialog,
                                                      isOpen:true,
                                                      title:'Are you sure to delete this record?',
                                                      subTitle:"You can't undo this operation",
                                                      onConfirm:()=>{
                                                        removePlacementHandler(customer._id)}
                                                      }
                                                    )
                    } } />
                    </Link>
                    <Link to={`/app/placementEditor/${customer._id}`}> <EditIcon color="primary" fontSize="small"></EditIcon></Link>
                  </TableCell>
                </TableRow>
              )
              })
              ) } 
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3
        }}
      >
        <Pagination color="primary" count={limit} size="small" page={page} onChange={handleChange} />
      </Box>
    </Card>
  )
};



export default PlacemtListResults;
